// color design tokens export
export const tokens = {
  grey: {
    100: "#f6f6f6",
    200: "#ffffff",
    300: "#070c3c00",
    400: "#F9F9F9",
    500: "#1e1e1e80",
    600: "#a7a7a7",
    700: "#F3F3F3",
    800: "#48494e",
    900: "#242427",
  },
  primary: {
    // light blue
    100: "#cce3fe",
    200: "#99c6fd",
    300: "#67aafc",
    400: "#348dfb",
    500: "#0171fa",
    600: "#015ac8",
    700: "#014496",
    800: "#002d64",
    900: "#001732",
  },
  secondary: {
    // Black
    100: "#0000004d",
    200: "#1e1e1eab",
    300: "#1e1e1e99",
    400: "#1e1e1e8a",
    500: "#1e1e1ecc",
    600: "#1e1e1e82",
    700: "#1E1E1E",
    800: "#11154B",
    900: "#000000",
  },
};

// mui theme settings
export const themeSettings = {
  palette: {
    // palette values for dark mode
    grey: {
      ...tokens.grey,
      main: tokens.grey[200],
    },
    primary: {
      ...tokens.primary,
      main: tokens.primary[100],
    },
    secondary: {
      ...tokens.secondary,
      main: tokens.secondary[500],
    },
    neutral: {
      ...tokens.grey,
    },
    background: {
      default: tokens.grey[100],
    },
    color: {
      default: tokens.secondary[500],
    },
  },
  breakpoints: {
    values: {
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  // direction: "rtl",
};
