import {
  iconDashboard,
  iconProfileManagement,
  iconSubmissions,
  iconNews,
  iconTermsConditions,
  iconPrivacyPolicy,
} from "../../assets/images";

export const sidebarConfig = [
  {
    icon: iconDashboard,
    title: "Dashboard",
    link: "",
    manager: false,
    superAdmin: false,
  },
  {
    icon: iconProfileManagement,
    title: "Profile Management",
    link: "profile-management",
    manager: true,
    superAdmin: true,
  },
  {
    icon: iconProfileManagement,
    title: "Managers",
    link: "user-management",
    manager: false,
    superAdmin: true,
  },
  // {
  //   icon: iconNews,
  //   title: "News",
  //   link: "news",
  //   manager: false,
  //   superAdmin: true,
  // },
  // {
  //   icon: iconTermsConditions,
  //   title: "Terms & Conditions",
  //   link: "terms-conditions",
  //   manager: false,
  //   superAdmin: true,
  // },
  // {
  //   icon: iconPrivacyPolicy,
  //   title: "Privacy & Policy",
  //   link: "privacy-policy",
  //   manager: false,
  //   superAdmin: true,
  // },
];
