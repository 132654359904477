/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CustomButton,
  CustomInput,
  Wrapper,
  Heading,
  InputDrag,
} from "../../components";
import { validationErrors } from "../../constants/validationErrors";
import { useTheme } from "@emotion/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils";
import { userUpdate, sendOtp, verifyOtp } from "../../slice/user.slice";
import { getUserData } from "../../services/authService";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  countryOptions,
  countryOptionsAr,
  countryOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const BankDetails = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const { palette } = useTheme();
  const userFormData = useSelector((state) => state.user?.userFormData);
  const [state, setState] = useAppState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [otpMode, setOtpMode] = useState(false);
  const [isVerifiedOtp, setVerifiedOtp] = useState(false);
  const [countryLists, setCountryList] = useState(countryOptions);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });
  let sendOtpMessage = useIntl().formatMessage({ id: "sendOtpMessage" });
  let otpSuccessMessage = useIntl().formatMessage({ id: "otpSuccessMessage" });
  let invalidOTP = useIntl().formatMessage({ id: "invalidOTP" });

  const Root = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    "& .MuiInputLabel-root": {
      textAlign: "left",
    },
    "& .MuiButton-root": {
      marginTop: "15px",
    },
    "& .reset": {
      background: palette.grey[200],
      borderRadius: "14px",
      padding: "20px",
      "& .MuiTypography-h2": {
        textAlign: "left",
        paddingBottom: "10px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .reset": {
        padding: "30px",
      },
    },
  }));

  const validationSchema = Yup.object().shape({
    BankName: Yup.string().required(
      useIntl().formatMessage({ id: "bankNameisRequired" })
    ),
    BeneficiaryName: Yup.string().required(
      useIntl().formatMessage({ id: "beneficiaryNameisRequired" })
    ),
    AccountNumber: Yup.string().required(
      useIntl().formatMessage({ id: "accountNumberisRequired" })
    ),
    IBAN: Yup.string().required(
      useIntl().formatMessage({ id: "iBANisRequired" })
    ),
    SWIFT_x002d_BIC: Yup.string().required(
      useIntl().formatMessage({ id: "sWIFT/BICisRequired" })
    ),
    BranchName: Yup.string().required(
      useIntl().formatMessage({ id: "branchNameisRequired" })
    ),
    BranchAddress: Yup.string().required(
      useIntl().formatMessage({ id: "branchAddressisRequired" })
    ),
    BranchAddress2: Yup.string().required(
      useIntl().formatMessage({ id: "branchAddress2isRequired" })
    ),
    BranchCity: Yup.string().required(
      useIntl().formatMessage({ id: "branchCityisRequired" })
    ),
    BranchRegion: Yup.string().required(
      useIntl().formatMessage({ id: "branchRegionisRequired" })
    ),
    BranchPostalCode: Yup.string().required(
      useIntl().formatMessage({ id: "branchPostalCodeisRequired" })
    ),
    BranchCountry: Yup.object().required(
      useIntl().formatMessage({ id: "branchCountryisRequired" })
    ),
    CorrespondentBank: Yup.string().required(
      useIntl().formatMessage({ id: "correspondentBankisRequired" })
    ),
    CorrespondentBankSWIFT: Yup.string().required(
      useIntl().formatMessage({ id: "CorrespondentBankSWIFTisRequired" })
    ),
    // BankReferenceLetterLink: isVerifiedOtp
    //   ? Yup.string().required(
    //       useIntl().formatMessage({ id: "copyOfBankReferenceLetter" })
    //     )
    //   : "",
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userFormData?.BankName && !state?.BankName) {
      reset({
        BankName: userFormData?.BankName,
        BeneficiaryName: userFormData?.BeneficiaryName,
        AccountNumber: userFormData?.AccountNumber,
        IBAN: userFormData?.IBAN,
        SWIFT_x002d_BIC: userFormData?.SWIFT_x002d_BIC,
        BranchName: userFormData?.BranchName,
        BranchAddress: userFormData?.BranchAddress,
        BranchAddress2: userFormData?.BranchAddress2,
        BranchCity: userFormData?.BranchCity,
        BranchRegion: userFormData?.BranchRegion,
        BranchPostalCode: userFormData?.BranchPostalCode,
        BranchCountry: countryLists.filter(
          (item) => item.value === userFormData?.BranchCountry
        )[0],
        CorrespondentBank: userFormData?.CorrespondentBank,
        CorrespondentBankSWIFT: userFormData?.CorrespondentBankSWIFT,
      });
    }
    if (locale == "ar") {
      setCountryList(countryOptionsAr);
    } else if (locale == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  }, [userFormData]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    setState({ ...state, ...finalData });
    if (isVerifiedOtp) {
      onNext();
    } else {
      if (JSON.parse(getUserData())?.sharepoint_reference_id) {
        if (
          userFormData?.AccountNumber != finalData?.AccountNumber ||
          userFormData?.BankName != finalData?.BankName ||
          userFormData?.BeneficiaryName != finalData?.BeneficiaryName ||
          userFormData?.IBAN != finalData?.IBAN
        ) {
          let payload = { email: JSON.parse(getUserData()).email };
          dispatch(sendOtp(payload))
            .then((res) => {
              setToast({
                type: "success",
                message: sendOtpMessage + " " + JSON.parse(getUserData()).email,
              });
              setLoading(false);
              setOtpMode(true);
            })
            .catch((err) => {
              setLoading(false);
              setToast({
                type: "error",
                message: "Something went wrong. Please try again later.",
              });
            });
        } else {
          onNext();
        }
      } else {
        onNext();
      }
    }
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCountryList(countryOptionsAr);
    } else if (lang == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  };

  const handleSendOtp = () => {
    setLoading(true);
    let payload = { email: JSON.parse(getUserData()).email };
    dispatch(sendOtp(payload))
      .then((res) => {
        setToast({
          type: "success",
          message: sendOtpMessage + " " + JSON.parse(getUserData()).email,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setToast({
          type: "error",
          message: "Something went wrong. Please try again later.",
        });
      });
  };

  const handleVerifyOtp = (data) => {
    setLoading(true);
    let payload = { email: JSON.parse(getUserData()).email, otp: data.otp };
    dispatch(verifyOtp(payload))
      .then((res) => {
        setToast({
          type: "success",
          message: otpSuccessMessage,
        });
        setLoading(false);
        setVerifiedOtp(true);
        setOtpMode(false);
        onNext();
      })
      .catch((err) => {
        setLoading(false);
        setToast({
          type: "error",
          message: invalidOTP,
        });
      });
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {otpMode ? (
            <Root>
              <Grid
                container
                dir={locale === "ar" ? "rtl" : "ltr"}
                sx={{
                  "&[dir|='rtl']": {
                    "& .MuiTypography-h2": { textAlign: "right" },
                    "& .MuiTypography-body1 a": {
                      marginRight: "10px",
                    },
                  },
                }}
              >
                <Grid item xl={4} lg={5} md={6} sm={8} xs={11} mx="auto">
                  <Box className="reset">
                    <Heading title={useIntl().formatMessage({ id: "otp" })} />
                    <CustomInput
                      label={useIntl().formatMessage({ id: "enterOtp" })}
                      type="text"
                      placeholder={useIntl().formatMessage({ id: "enterOtp" })}
                      name="otp"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.otp?.message}
                      control={control}
                    />
                    <CustomButton
                      title={useIntl().formatMessage({ id: "submit" })}
                      size="small"
                      type="submit"
                      full
                      loading={loading}
                      onClick={handleSubmit((data) => handleVerifyOtp(data))}
                    />
                    <Box
                      sx={{ marginBlock: "20px 0px", cursor: "pointer" }}
                      onClick={() => handleSendOtp()}
                    >
                      {!loading && (
                        <Typography
                          variant="body1"
                          display="flex"
                          justifyContent="center"
                          gap="10px"
                          flexWrap="wrap"
                          align="center"
                        >
                          <FormattedMessage id="resendOTP" />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Root>
          ) : (
            <Wrapper>
              <Box
                sx={
                  userFormData.Status === "In Progress"
                    ? {
                        opacity: [0.9, 0.8, 0.7],
                        pointerEvents: "none",
                      }
                    : {}
                }
              >
                <Grid
                  container
                  sx={{
                    "& .MuiGrid-container": {
                      marginBottom: "0 !important",
                      "& .MuiGrid-root": {
                        marginBottom: "20px",
                      },
                    },
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  }}
                >
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "bankName" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBankName",
                      })}
                      name="BankName"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BankName?.message}
                      control={control}
                      onChange={(e) => alert("ok")}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "beneficiaryName" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBeneficiaryName",
                      })}
                      name="BeneficiaryName"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BeneficiaryName?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "accountNumber" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterAccountNumber",
                      })}
                      name="AccountNumber"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.AccountNumber?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "IBAN" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterIBAN",
                      })}
                      name="IBAN"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.IBAN?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "SWIFT/BIC" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterSWIFT/BIC",
                      })}
                      name="SWIFT_x002d_BIC"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.SWIFT_x002d_BIC?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "branchName" })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBranchName",
                      })}
                      name="BranchName"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BranchName?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "branchAddressLine1",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBranchAddressLine1",
                      })}
                      name="BranchAddress"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BranchAddress?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "branchAddressLine2",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBranchAddressLine2",
                      })}
                      name="BranchAddress2"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BranchAddress2?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid
                    container
                    columnSpacing={3}
                    sx={{ marginBottom: "20px" }}
                  >
                    <Grid item sm={6} xs={12}>
                      <CustomInput
                        label={useIntl().formatMessage({ id: "bankCity" })}
                        type="text"
                        placeholder={useIntl().formatMessage({
                          id: "enterBankCity",
                        })}
                        name="BranchCity"
                        rules={{
                          required: validationErrors.required,
                        }}
                        error={errors?.BranchCity?.message}
                        control={control}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomInput
                        label={useIntl().formatMessage({
                          id: "state/Province/Region",
                        })}
                        type="text"
                        placeholder={useIntl().formatMessage({
                          id: "enterState/Province/Region",
                        })}
                        name="BranchRegion"
                        rules={{
                          required: validationErrors.required,
                        }}
                        error={errors?.BranchRegion?.message}
                        control={control}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomInput
                        label={useIntl().formatMessage({
                          id: "bankPostalCode",
                        })}
                        type="text"
                        placeholder={useIntl().formatMessage({
                          id: "enterBankPostalCode",
                        })}
                        name="BranchPostalCode"
                        rules={{
                          required: validationErrors.required,
                        }}
                        error={errors?.BranchPostalCode?.message}
                        control={control}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Box component="div" className="form-group">
                        <InputLabel shrink htmlFor="bootstrap-input">
                          <FormattedMessage id="bankCountry" />
                        </InputLabel>
                        <Controller
                          name="BranchCountry"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              options={countryLists}
                            />
                          )}
                        />
                        {errors?.BranchCountry?.message && (
                          <FormHelperText error>
                            {errors?.BranchCountry?.message}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "correspondentBank",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterCorrespondentBank",
                      })}
                      name="CorrespondentBank"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.CorrespondentBank?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "correspondentBankSWIFT",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterCorrespondentBankSWIFT",
                      })}
                      name="CorrespondentBankSWIFT"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.CorrespondentBankSWIFT?.message}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* {isVerifiedOtp && (
                <Grid item lg={12} sm={12} xs={12}>
                  <InputDrag
                    errors={errors}
                    control={control}
                    title={useIntl().formatMessage({
                      id: "copyOfBankReferenceLetter",
                    })}
                    name="BankReferenceLetterLink"
                    rules={{
                      required: "Bank Reference Letter is required",
                    }}
                    values={watch()}
                    disable={
                      userFormData.Status === "In Progress" ? true : false
                    }
                  />
                </Grid>
              )} */}
            </Wrapper>
          )}

          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  onClick={onPrev}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  size="small"
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                  loading={loading}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default BankDetails;
