/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CustomButton, InputDrag, Wrapper } from "../../components";
import { useTheme } from "@emotion/react";
import { useAppState } from "./state";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showToast } from "../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";

const SupportingDocuments = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const { palette } = useTheme();
  const { id } = useParams();
  const [state, setState] = useAppState();
  const userFormData = useSelector((state) => state.user.userFormData);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
  } = useForm({ mode: "onChange", defaultValues: state });

  useEffect(() => {
    if (userFormData?.ValidTradeLicenseLink && !state.ValidTradeLicenseLink) {
      reset({
        ValidTradeLicenseLink: userFormData?.ValidTradeLicenseLink,
        CommercialRegistryLink: userFormData?.CommercialRegistryLink,
        TaxRegistrationCertificateLink:
          userFormData?.TaxRegistrationCertificateLink,
        BankReferenceLetterLink: state.otp
          ? ""
          : userFormData?.BankReferenceLetterLink,
        UltimateOwnerIDLink: userFormData?.UltimateOwnerIDLink,
      });
    }
    if (userFormData?.FinancialStatementLink && !state.FinancialStatementLink) {
      setValue("FinancialStatementLink", userFormData?.FinancialStatementLink);
      setValue("IsFinancialStatementAvailable", true);
    }
  }, [userFormData]);

  useEffect(() => {
    if (!id) {
      setValue("IsFinancialStatementAvailable", true);
    }
  }, [id]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    if (!data.IsFinancialStatementAvailable) {
      delete finalData.FinancialStatementLink;
    }
    setState({ ...state, ...finalData });
    onNext();
  };

  const IsFinancialStatementAvailable = watch("IsFinancialStatementAvailable");

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <Wrapper>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              sx={{
                "& .MuiGrid-container": {
                  marginBottom: "0 !important",
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                },
                "& .MuiGrid-root": {
                  marginBottom: "20px",
                },
              }}
            >
              <Typography color="red">
                Kindly attach the documents in English or French
              </Typography>
              <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                sx={
                  userFormData.Status === "In Progress"
                    ? {
                        opacity: [0.9, 0.8, 0.7],
                        pointerEvents: "none",
                      }
                    : {}
                }
              >
                <Controller
                  name="IsFinancialStatementAvailable"
                  control={control}
                  render={({ value, field: { onChange } }) => (
                    <FormControlLabel
                      name="IsFinancialStatementAvailable"
                      control={
                        <Checkbox
                          checked={IsFinancialStatementAvailable ? true : false}
                          onChange={onChange}
                        />
                      }
                      label={useIntl().formatMessage({
                        id: "FinancialStatementAvailable",
                      })}
                    />
                  )}
                />
              </Grid>
              {IsFinancialStatementAvailable && (
                <Grid item lg={12} sm={12} xs={12}>
                  <InputDrag
                    rules={{
                      required: IsFinancialStatementAvailable
                        ? "Financial Statement is required"
                        : false,
                    }}
                    title={useIntl().formatMessage({
                      id: "copyOfFinancialStatement",
                    })}
                    errors={errors}
                    name="FinancialStatementLink"
                    control={control}
                    values={watch()}
                    disable={
                      userFormData.Status === "In Progress" ? true : false
                    }
                  />
                </Grid>
              )}
              <Grid item lg={12} sm={12} xs={12}>
                <InputDrag
                  rules={{
                    required: "Valid Trade License is required",
                  }}
                  title={useIntl().formatMessage({
                    id: "copyOfValidTradeLicense",
                  })}
                  errors={errors}
                  name="ValidTradeLicenseLink"
                  control={control}
                  values={watch()}
                  disable={userFormData.Status === "In Progress" ? true : false}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <InputDrag
                  rules={{
                    required: "Commercial Registry is required",
                  }}
                  title={useIntl().formatMessage({
                    id: "copyOfCommercialRegistry",
                  })}
                  errors={errors}
                  name="CommercialRegistryLink"
                  control={control}
                  values={watch()}
                  disable={userFormData.Status === "In Progress" ? true : false}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <InputDrag
                  errors={errors}
                  control={control}
                  title={useIntl().formatMessage({
                    id: "copyTaxRegistrationCertificate",
                  })}
                  name="TaxRegistrationCertificateLink"
                  rules={{
                    required: "Tax Registration Certificate is required",
                  }}
                  values={watch()}
                  disable={userFormData.Status === "In Progress" ? true : false}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <InputDrag
                  errors={errors}
                  control={control}
                  title={useIntl().formatMessage({
                    id: "copyOfBankReferenceLetter",
                  })}
                  name="BankReferenceLetterLink"
                  rules={{
                    required: "Bank Reference Letter is required",
                  }}
                  values={watch()}
                  disable={userFormData.Status === "In Progress" ? true : false}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <InputDrag
                  errors={errors}
                  control={control}
                  title={useIntl().formatMessage({
                    id: "copyOfUltimateOwnerPassportAndNationalID",
                  })}
                  name="UltimateOwnerIDLink"
                  rules={{
                    required:
                      "Ultimate Owner Passport and National ID is required",
                  }}
                  values={watch()}
                  disable={userFormData.Status === "In Progress" ? true : false}
                />
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
        <Box component="div">
          <Box className="dashboard-footer">
            <Box className="footer-item">
              <Typography
                color={
                  locale === "en"
                    ? palette.secondary[700]
                    : palette.secondary[400]
                }
                onClick={() => selectLanguage("en")}
              >
                <FormattedMessage id="english" />
              </Typography>
              <Typography
                color={
                  locale === "fr"
                    ? palette.secondary[700]
                    : palette.secondary[400]
                }
                onClick={() => selectLanguage("fr")}
              >
                <FormattedMessage id="french" />
              </Typography>
              <Typography
                color={
                  locale === "ar"
                    ? palette.secondary[700]
                    : palette.secondary[400]
                }
                onClick={() => selectLanguage("ar")}
              >
                <FormattedMessage id="arabic" />
              </Typography>
            </Box>
            <Box display="flex" columnGap="10px">
              <CustomButton
                title={useIntl().formatMessage({ id: "back" })}
                outline
                size="small"
                onClick={onPrev}
              />
              <CustomButton
                title={useIntl().formatMessage({ id: "next" })}
                size="small"
                type="submit"
                onClick={handleSubmit((data) => onSubmit(data, "next"))}
              />
              {/* {userFormData && userFormData.Status !== "In Progress" && (
                <CustomButton
                  title={useIntl().formatMessage({ id: "update" })}
                  type="submit"
                  size="small"
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit((data) => onSubmit(data, "update"))}
                />
              )} */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SupportingDocuments;
