import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { sidebarConfig } from "./sidebarConfig";
import { useTheme } from "@emotion/react";
import { iconArrowRight } from "../../assets/images";
import { managerLogout } from "../../slice/user.slice";

const SideBar = ({ show }) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const userSelector = JSON.parse(sessionStorage.getItem("ola_user_data"));
  console.log("SideBar ~ userSelector:", userSelector);

  const sidebarData =
    userSelector?.user_type === "manager"
      ? sidebarConfig?.filter((item) => item?.manager)
      : userSelector?.user_type === "superadmin"
      ? sidebarConfig?.filter((item) => item?.superAdmin)
      : [];

  const Wrapper = styled("div")(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    background: palette.grey[200],
    boxShadow: " 0px 10px 20px rgba(178, 191, 210, 0.25)",
    padding: `${isNonMobile ? "16px" : "5px"}`,
    zIndex: 1,
    height: "100vh",
    width: "290px",
    transition: "all 0.5s ease",
    transform: `translateX(-100%)`,
    [theme.breakpoints.up("md")]: {
      "& .logo": { paddingBottom: "0" },
      transform: "translateX(0)",
      position: "absolute",
    },

    "&.active": {
      width: "280px",
      transform: `translateX(0)`,
    },
    "& ul": {
      padding: 0,
      "& a": {
        "& .MuiListItemButton-root": {
          padding: `${isNonMobile ? "12px 16px" : "10px"}`,
          borderRadius: `${isNonMobile ? "9px" : "5px"}`,
          transition: "all 0.3s ease",
          "&:hover": {
            background: palette.primary[500],
            "& img": {
              filter: "brightness(0) invert(1)",
            },
            "& .MuiListItemText-root": {
              "& .MuiTypography-root": {
                color: palette.grey[200],
              },
            },
          },
          "& .MuiListItemIcon-root": {
            marginRight: "8px",
            "& img": {
              width: `${isNonMobile ? "25px" : "22px"}`,
              height: `${isNonMobile ? "25px" : "22px"}`,
            },
          },
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              fontWeight: "500",
              fontSize: "19px",
              fontFamily: "var(--font_et_r)",
              color: palette.secondary[900],
            },
          },
        },
      },
    },
  }));

  return (
    <Wrapper className={show && "active"}>
      <List>
        {sidebarData?.map((items, index) => {
          const { icon, title, link } = items;
          return (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <NavLink to={`/dashboard/${link}`}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                    }}
                  >
                    <img src={icon} alt="title" />
                  </ListItemIcon>
                  <ListItemText primary={title} sx={{ opacity: 1 }} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          );
        })}
        <ListItem>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "center",
              px: 2.5,
            }}
            onClick={() => managerLogout()}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: "center",
              }}
            >
              <img src={iconArrowRight} alt="title" />
            </ListItemIcon>
            <ListItemText primary={"Logout"} sx={{ opacity: 1 }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Wrapper>
  );
};

export default SideBar;
