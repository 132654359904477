import { useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";
import { AppProvider } from "./pages/registration/state";
import { LanguageContextWrapper } from "./context/languageContext";

import {
  News,
  Home,
  HomeManager,
  Success,
  Dashboard,
  MainLayout,
  Submissions,
  Registration,
  PrivacyPolicy,
  TermsConditions,
  ProfileManagement,
  ResetPassword,
  UserManagement,
} from "./pages";
import { Provider } from "react-redux";
import store from "./store";
import ForgotPassword from "./pages/forgot-password";
import ChangePassword from "./pages/change-password";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: "5db34d60-9c46-4131-8b98-0f3d817db21c",
    authority: `https://login.microsoftonline.com/d9ca4589-67cc-41b3-8883-62a1ed7f4390`,
  },
};

const pca = new PublicClientApplication(configuration);

function App() {
  const theme = useMemo(() => createTheme(themeSettings), []);

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <GlobalTheme theme={theme} />
        <CssBaseline />
        <Provider store={store}>
          <AppProvider>
            <LanguageContextWrapper>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/admin-portal" element={<HomeManager />} />
                  {/*  Dashboard All Pages Routing  */}
                  <Route path="/dashboard" element={<MainLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="user/:id" element={<Registration />} />
                    <Route path="registration" element={<Registration />} />
                    <Route
                      path="profile-management"
                      element={<ProfileManagement />}
                    />
                    <Route
                      path="user-management"
                      element={<UserManagement />}
                    />
                    <Route path="news" element={<News />} />
                    <Route path="submissions" element={<Submissions />} />
                    <Route
                      path="terms-conditions"
                      element={<TermsConditions />}
                    />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  </Route>
                  <Route path="success" element={<Success />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="change-password" element={<ChangePassword />} />
                </Routes>
              </BrowserRouter>
            </LanguageContextWrapper>
          </AppProvider>
        </Provider>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
