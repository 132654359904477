import React from "react";
import { Heading, Wrapper } from "../components";
const submissions = () => {
  return (
    <Wrapper>
      <Heading title="submissions" />
    </Wrapper>
  );
};

export default submissions;
