import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, Wrapper } from "../components";
const Dashboard = () => {
  const navigate = useNavigate();
  let access_token = sessionStorage.getItem("access_token");
  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
  }, []);
  return (
    <Wrapper>
      <Heading title="Dashboard" />
    </Wrapper>
  );
};

export default Dashboard;
