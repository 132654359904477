/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton, CustomInput, Wrapper } from "../../components";
import { validationErrors } from "../../constants/validationErrors";
import { useTheme } from "@emotion/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils";
import { userUpdate } from "../../slice/user.slice";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  currencyOptions,
  currencyOptionsAr,
  currencyOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const FinancialInformation = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const { palette } = useTheme();
  const userSelector = useSelector((state) => state.user);
  const [state, setState] = useAppState();
  const [currencyList, setCurrencyList] = useState("");
  const userFormData = userSelector?.userFormData;
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });

  const validationSchema = Yup.object().shape({
    InsuranceDetails: Yup.string().required(
      useIntl().formatMessage({ id: "insuranceDetailsisRequired" })
    ),
    ShareCapital: Yup.number()
      .typeError(useIntl().formatMessage({ id: "shareCapitalshouldbeanumber" }))
      .required(useIntl().formatMessage({ id: "shareCapitalisRequired" })),
    ShareCapitalCurrency: Yup.object().required(
      useIntl().formatMessage({ id: "shareCapitalCurrencyisRequired" })
    ),
    ActualTurnover: Yup.number()
      .typeError(
        useIntl().formatMessage({ id: "actualTurnovershouldbeanumber" })
      )
      .required(useIntl().formatMessage({ id: "actualTurnoverisRequired" })),
    ExpectedTurnover: Yup.number()
      .typeError(
        useIntl().formatMessage({ id: "expectedTurnovershouldbeanumber" })
      )
      .required(useIntl().formatMessage({ id: "expectedTurnoverisRequired" })),
    TaxRegistrationNumber: Yup.string().required(
      useIntl().formatMessage({ id: "taxRegistrationNumberisRequired" })
    ),
  });

  useEffect(() => {
    let ShareCapitalCurrency = userFormData?.ShareCapitalCurrency
      ? userFormData?.ShareCapitalCurrency
      : "AED";
    if (userFormData?.InsuranceDetails && !state?.InsuranceDetails) {
      const oldData = {
        InsuranceDetails: userFormData?.InsuranceDetails,
        ShareCapital: userFormData?.ShareCapital,
        ShareCapitalCurrency: currencyList.length
          ? currencyList.filter(
              (item) => item.value === ShareCapitalCurrency
            )[0]
          : "",
        ActualTurnover: userFormData?.ActualTurnover,
        ExpectedTurnover: userFormData?.ExpectedTurnover,
        TaxRegistrationNumber: userFormData?.TaxRegistrationNumber,
      };
      reset({
        ...oldData,
      });
    }
    if (locale == "ar") {
      setCurrencyList(currencyOptionsAr);
    } else if (locale == "fr") {
      setCurrencyList(currencyOptionsFr);
    } else {
      setCurrencyList(currencyOptions);
    }
  }, [userFormData, currencyList]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    setState({ ...state, ...finalData });
    onNext();
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCurrencyList(currencyOptionsAr);
    } else if (lang == "fr") {
      setCurrencyList(currencyOptionsFr);
    } else {
      setCurrencyList(currencyOptions);
    }
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      borderBottom: `1px solid ${palette.secondary[100]}`,
                      marginBottom: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Typography
                      fontSize="18px"
                      color={palette.secondary[200]}
                      fontFamily="var(--font_et_r)"
                    >
                      {useIntl().formatMessage({
                        id: "vendorFinancialNote",
                      })}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    rules={{
                      required: validationErrors.required,
                    }}
                    label={useIntl().formatMessage({ id: "insuranceDetails" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterInsuranceDetails",
                    })}
                    name="InsuranceDetails"
                    error={errors?.InsuranceDetails?.message}
                    control={control}
                  />
                </Grid>
                <Grid container columnSpacing={3} sx={{ marginBottom: "20px" }}>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({ id: "shareCapital" })}
                      type="number"
                      placeholder={useIntl().formatMessage({
                        id: "enterShareCapital",
                      })}
                      name="ShareCapital"
                      error={errors?.ShareCapital?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box component="div" className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="ShareCapitalCurrency" />
                      </InputLabel>
                      <Controller
                        name="ShareCapitalCurrency"
                        control={control}
                        rules={{
                          required: validationErrors.required,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            options={currencyList}
                          />
                        )}
                      />
                      {errors?.ShareCapitalCurrency?.message && (
                        <FormHelperText error>
                          {errors?.ShareCapitalCurrency?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "actualTurnoverPrevious12Months",
                      })}
                      type="number"
                      placeholder={useIntl().formatMessage({
                        id: "enterActualTurnoverPrevious12months",
                      })}
                      name="ActualTurnover"
                      error={errors?.ActualTurnover?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "expectedTurnoverCurrentYear",
                      })}
                      type="number"
                      placeholder={useIntl().formatMessage({
                        id: "enterExpectedTurnoverCurrentYear",
                      })}
                      name="ExpectedTurnover"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.ExpectedTurnover?.message}
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "taxRegistrationNumber",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterTaxRegistrationNumber",
                    })}
                    name="TaxRegistrationNumber"
                    error={errors?.TaxRegistrationNumber?.message}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  onClick={onPrev}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  size="small"
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                />
                {/* {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleSubmit((data) => onSubmit(data, "update"))}
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default FinancialInformation;
