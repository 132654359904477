import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = ({ title, children }) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        marginBottom: "20px",
        "& .MuiTypography-h2": {
          textTransform: "capitalize",
          fontWeight: "600",
          fontSize: "30px",
          fontFamily: "var(--font-p_sb)",
          color: palette.secondary[700],
        },
      }}
    >
      <Typography variant="h2">{title}</Typography>
      {children && children}
    </Box>
  );
};

export default Heading;
