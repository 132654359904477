import { useTheme } from "@emotion/react";
import { Button, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { iconArrowRight } from "../../assets/images";
import styled from "@emotion/styled";

const ImgMediaCard = ({ item }) => {
  const { img, date, title, description } = item;
  const { palette } = useTheme();
  const Root = styled("div")(({ theme }) => ({
    borderRadius: "14px",
    boxShadow: "none",
    background: palette.grey[200],
    "& .MuiCardContent-root": {
      padding: "10px 15px 22px 18px",
    },
    "& .MuiCardMedia-media": {
      objectFit: "fill",
      height: "auto",
    },
    "& .MuiButtonBase-root": {
      padding: 0,
      color: palette.primary[500],
      fontFamily: "var(--font-p_r)",
      fontSize: "14px",
      textTransform: "capitalize",
      "& img": {
        transition: "0.3s",
        marginLeft: "10px",
      },
      "&:hover": {
        "& img": {
          marginLeft: "5px",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiCardMedia-media": {
        // height: "270px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiCardMedia-media": {
        height: "250px",
      },
    },
    
  }));
  return (
    <Root>
      <CardMedia component="img" alt="green iguana" height="289" image={img} />
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          fontSize="14px"
          color={palette.grey[500]}
          fontFamily="var(--font-p_r)"
          marginBottom="6px"
        >
          {date}
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontSize="19px"
          color={palette.secondary[700]}
          fontFamily="var(--font_et_r)"
          marginBottom="13px"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontSize="16px"
          color={palette.secondary[500]}
          fontFamily="var(--font_et_r)"
          marginBottom="13px"
        >
          {description}
        </Typography>
        <Button size="small">
          Continue reading <img src={iconArrowRight} alt="" />
        </Button>
      </CardContent>
    </Root>
  );
};

export default ImgMediaCard;
