import React from "react";
import { Heading, Wrapper } from "../components";
const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Heading title="Privacy Policy" />
    </Wrapper>
  );
};

export default PrivacyPolicy;
