import React, { useEffect } from "react";
import { Heading, Wrapper } from "../components";
import { Button, Snackbar, SnackbarContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Select from "react-select";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getAdminProfile,
  changeAdminProfileStatus,
} from "../slice/adminProfile.slice";

import { forgotPassword, accountActivationLink } from "../slice/user.slice";
const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "In-Active" },
];

const ProfileManagement = () => {
  const [rows, setRows] = React.useState([]);
  const dispatch = useDispatch();
  const [toast, setToast] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(getAdminProfile()).then((data) => {
      setRows(data);
      setLoader(false);
    });
  };

  const changeStatus = (id, status) => {
    setLoader(true);
    const body = {
      status,
    };
    dispatch(changeAdminProfileStatus(id, body))
      .then((res) => {
        init();
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleResetPassword = (email) => {
    setLoader(true);

    const body = {
      email,
    };
    dispatch(forgotPassword(body))
      .then((data) => {
        setLoader(false);
        setToast({ type: "success", message: data?.data?.msg });
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const accountActivation = (email) => {
    setLoader(true);
    const body = {
      email,
    };
    dispatch(accountActivationLink(body))
      .then((data) => {
        setLoader(false);
        setToast({ type: "success", message: data?.data?.data?.msg });
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const columns = [
    {
      field: "user_id",
      headerName: "User Id",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "entity_name",
      headerName: "Entity Name",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sharepoint_reference_id",
      headerName: "MS Reference No.",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user_type",
      headerName: "User Type",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updated_at",
      headerName: "Last updated",
      align: "center",
      type: "date",
      headerAlign: "center",
      width: 150,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <>
          {
            <div style={{ lineHeight: "26px", position: "absolute" }}>
              <Select
                value={statusOptions.find(
                  (e) => e.value.toString() === params.value.toString()
                )}
                options={statusOptions}
                onChange={(e) => changeStatus(params.row.user_id, e.value)}
              />
            </div>
          }
        </>
      ),
    },
    {
      field: "passwordReset",
      headerName: "Reset Password",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor: params.value === 1 ? "rgb(44 139 93)" : "#d5d5d5",
          }}
          // startIcon={<ActiveIcon />}
          onClick={() => handleResetPassword(params.row.email)}
        >
          {"Reset Password"}
        </Button>
      ),
    },
    {
      field: "accountActivation",
      headerName: "Account Activation",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor: params.value === 1 ? "rgb(44 139 93)" : "#d5d5d5",
          }}
          // startIcon={<ActiveIcon />}
          onClick={() => accountActivation(params.row.email)}
        >
          {"Activation Link"}
        </Button>
      ),
    },
  ];
  return (
    <Wrapper>
      {toast?.type && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          itemType="error"
          open={toast?.type}
          autoHideDuration={5000}
          onClose={() => setToast(false)}
        >
          <SnackbarContent
            style={{
              backgroundColor: toast?.type === "success" ? "black" : "red",
            }}
            message={toast?.message}
          />
        </Snackbar>
      )}
      <Heading title="Profile Management" />
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        loading={loader}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Wrapper>
  );
};

export default ProfileManagement;
