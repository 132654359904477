/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@emotion/react";
import { LanguageContext } from "../context/languageContext";
import { FormGroup, MenuItem, Select } from "@mui/material";

const CustomSelect = () => {
  const { palette } = useTheme();

  const { locale, selectLanguage } = useContext(LanguageContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  let storeLang = sessionStorage.getItem("preferredLanguage");
  return (
    <FormGroup
      sx={{
        "& .MuiInputBase-root": {
          width: { xs: "150px", sm: "220px" },
          background: palette.grey[200],
          textAlign: "left",
          fontFamily: "var(--font-p_sb)",
          "& fieldset": {
            border: "none",
          },
        },
      }}
    >
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={locale ? locale : storeLang ? storeLang : "en"}
        onChange={selectLanguage}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        defaultValue={locale}
      >
        <MenuItem value={"en"}>
          <FormattedMessage id="en" />
        </MenuItem>
        <MenuItem value={"ar"}>
          <FormattedMessage id="ar" />
        </MenuItem>
        <MenuItem value={"fr"}>
          <FormattedMessage id="fr" />
        </MenuItem>
      </Select>
    </FormGroup>
  );
};

export default CustomSelect;
