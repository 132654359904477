import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
export default function CustomInput({
  label,
  type,
  placeholder,
  name,
  error,
  control,
  rules,
  rows,
  handleClickShowPassword,
  showPassword,
  isPassword,
  disabled,
}) {
  return (
    <Box component="div" className="form-group">
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <FormControl>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              type={type}
              placeholder={placeholder}
              name={name}
              {...field}
              focused={false}
              multiline={rows ? true : false}
              rows={rows}
              InputProps={{
                endAdornment: isPassword && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Typography variant="body1" className="error">
          {error}{" "}
        </Typography>
        {/* {error && <FormHelperText error>{error}</FormHelperText>} */}
      </FormControl>
    </Box>
  );
}
