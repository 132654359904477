/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton, CustomInput, Wrapper } from "../../components";
import { useTheme } from "@emotion/react";
import Select from "react-select";
import { useAppState } from "./state";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils";
import { userUpdate } from "../../slice/user.slice";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  countryOptions,
  countryOptionsAr,
  countryOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const References = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const { palette } = useTheme();
  const [countryLists, setCountryList] = useState(countryOptions);
  const userFormData = useSelector((state) => state.user.userFormData);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });

  const [state, setState] = useAppState();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: state,
    mode: "onChange",
  });

  useEffect(() => {
    reset({
      CustomerReference1Name: userFormData.CustomerReference1Name,
      CustomerReference1Address: userFormData.CustomerReference1Address,
      CustomerReference1Address2: userFormData.CustomerReference1Address2,
      CustomerReference1City: userFormData.CustomerReference1City,
      CustomerReference1PostalCode: userFormData.CustomerReference1PostalCode,
      CustomerReference1Region: userFormData.CustomerReference1Region,
      CustomerReference1Country: countryLists.filter(
        (option) => option.value === userFormData.CustomerReference1Country
      )[0],
      CustomerReference2Country: countryLists.filter(
        (option) => option.value === userFormData.CustomerReference2Country
      )[0],
      CustomerReference1MajorProductsL:
        userFormData.CustomerReference1MajorProductsL,
      CustomerReference2Name: userFormData.CustomerReference2Name,
      CustomerReference2Address: userFormData.CustomerReference2Address,
      CustomerReference2Address2: userFormData.CustomerReference2Address2,
      CustomerReference2City: userFormData.CustomerReference2City,
      CustomerReference2PostalCode: userFormData.CustomerReference2PostalCode,
      CustomerReference2Region: userFormData.CustomerReference2Region,
      CustomerReference2MajorProductsL:
        userFormData.CustomerReference2MajorProductsL,
    });
    if (locale == "ar") {
      setCountryList(countryOptionsAr);
    } else if (locale == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  }, [userFormData]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    setState({ ...state, ...finalData });
    onNext();
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCountryList(countryOptionsAr);
    } else if (lang == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "customerReference1",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterCustomerReference1",
                    })}
                    name="CustomerReference1Name"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "referenceAddress1" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterReferenceAddress1",
                    })}
                    name="CustomerReference1Address"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "referenceAddress2" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterReferenceAddress2",
                    })}
                    name="CustomerReference1Address2"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "city" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "city",
                    })}
                    name="CustomerReference1City"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "postalCode" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "postalCode",
                    })}
                    name="CustomerReference1PostalCode"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "state/Province/Region",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "state/Province/Region",
                    })}
                    name="CustomerReference1Region"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="country" />
                    </InputLabel>
                    <Controller
                      name="CustomerReference1Country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={countryLists}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "majorProductLine" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "majorProductLine",
                    })}
                    name="CustomerReference1MajorProductsL"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "customerReference2",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterCustomerReference2",
                    })}
                    name="CustomerReference2Name"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "referenceAddress1" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterReferenceAddress1",
                    })}
                    name="CustomerReference2Address"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "referenceAddress2" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterReferenceAddress2",
                    })}
                    name="CustomerReference2Address2"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "city" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "city",
                    })}
                    name="CustomerReference2City"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "postalCode" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "postalCode",
                    })}
                    name="CustomerReference2PostalCode"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "state/Province/Region",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "state/Province/Region",
                    })}
                    name="CustomerReference2Region"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="country" />
                    </InputLabel>
                    <Controller
                      name="CustomerReference2Country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={countryLists}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "majorProductLine" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterMajorProductLine",
                    })}
                    name="CustomerReference2MajorProductsL"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  onClick={onPrev}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  size="small"
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                />
                {/* {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleSubmit((data) => onSubmit(data, "update"))}
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default References;
