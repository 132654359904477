import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/axios";
import { saveUserData, saveToken } from "../services/authService";
import { getUserData, destroy } from "../services/authService";

const initialState = {
  loading: true,
  error: false,
  user: [],
  progress: 10,
  fileName: 0,
  userFormData: "",
};

function intersect_arrays(a, b) {
  var sorted_a = a.concat().sort();
  var sorted_b = b.concat().sort();
  var common = [];
  var a_i = 0;
  var b_i = 0;

  while (a_i < a.length && b_i < b.length) {
    if (sorted_a[a_i] === sorted_b[b_i]) {
      common.push(sorted_a[a_i]);
      a_i++;
      b_i++;
    } else if (sorted_a[a_i] < sorted_b[b_i]) {
      a_i++;
    } else {
      b_i++;
    }
  }
  return common;
}

const noCompliance = [
  "NumberOfEmployees",
  "InsuranceDetails",
  "ShareCapital",
  "ShareCapitalCurrency",
  "ActualTurnover",
  "ExpectedTurnover",
  "ContactVOIPType",
  "ContactVoIPID",
  "CustomerReference1Name",
  "CustomerReference1Address",
  "CustomerReference1MajorProductsL",
  "CustomerReference2Name",
  "CustomerReference2Address",
  "CustomerReference2MajorProductsL",
];

const apCompliance = [
  "AffiliateCode",
  "VendorPhone",
  "VendorWebsite",
  "BankName",
  "AccountNumber",
  "BeneficiaryName",
  "IBAN",
  "SWIFT_x002d_BIC",
  "BranchName",
  "BranchAddress",
  "BranchAddress2",
  "BranchCity",
  "BranchPostalCode",
  "BranchRegion",
  "BranchCountry",
  "CorrespondentBank",
  "CorrespondentBankSWIFT",
  "PaymentCurrency",
  "DocumentDeliveryMethod",
  "PreferredMethodOfPayment",
  "RetainagePercentage",
  "ContactPersonFirstName",
  "ContactPersonLastName",
  "ContactAddress",
  "ContactAddress2",
  "ContactCity",
  "ContactPostalCode",
  "ContactRegion",
  "ContactCountry",
  "ContactPhone",
  "ContactEmail",
];

const normal = [
  "EntityName",
  "LegalStatus",
  "CountryofIncorporation",
  "DateOfIncorporation",
  "BusinessRegistrationNumber",
  "BusinessRegistrationExpiryDate",
  "NatureOfBusiness",
  "VendorAddress",
  "VendorAddress2",
  "VendorCity",
  "VendorPostalCode",
  "VendorRegion",
  "VendorCountry",
  "ParentCompany",
  "UltimateOwner",
  "Nationality",
  "NationalIDNumber",
  "TaxRegistrationNumber",
];

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setProgress: (state, { payload }) => {
      state.progress = payload?.progress;
      state.fileName = payload?.fileName;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.userFormData = payload.data;
    },
    setUserFormData: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.userFormData = payload.data;
      state.user = payload.user;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setUser, setProgress, setError, setUserFormData } =
  userSlice.actions;

const { reducer } = userSlice;
export default reducer;

export const userRegister = (body) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      for (var key in body) {
        body[key] = body[key]?.label ? body[key].value : body[key];
      }
      let payload = { ...body, VendorEmail: JSON.parse(getUserData())?.email };

      const files = {
        ValidTradeLicenseLink: body.ValidTradeLicenseLink,
        CommercialRegistryLink: body.CommercialRegistryLink,
        TaxRegistrationCertificateLink: body.TaxRegistrationCertificateLink,
        BankReferenceLetterLink: body.BankReferenceLetterLink,
        UltimateOwnerIDLink: body.UltimateOwnerIDLink,
      };

      const allFiles = body.FinancialStatementLink
        ? {
            ...files,
            FinancialStatementLink: body.FinancialStatementLink,
          }
        : files;
      let count = 0;

      try {
        if (
          payload?.VendorEmail === "info@icogt.com" ||
          payload?.VendorEmail === "shaheryar.devlancer@gmail.com"
        ) {
          payload = {
            ...payload,
            ValidTradeLicenseLink: {
              __metadata: { type: "SP.FieldUrlValue" },
              Url: "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548874377.pdf",
              Description:
                "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548874377.pdf",
            },
            CommercialRegistryLink: {
              __metadata: { type: "SP.FieldUrlValue" },
              Url: "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548874412.pdf",
              Description:
                "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548874412.pdf",
            },
            TaxRegistrationCertificateLink: {
              __metadata: { type: "SP.FieldUrlValue" },
              Url: "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875859.pdf",
              Description:
                "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875859.pdf",
            },
            BankReferenceLetterLink: {
              __metadata: { type: "SP.FieldUrlValue" },
              Url: "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875675.pdf",
              Description:
                "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875675.pdf",
            },
            UltimateOwnerIDLink: {
              __metadata: { type: "SP.FieldUrlValue" },
              Url: "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875773.pdf",
              Description:
                "https://vendordocuments.blob.core.windows.net/vendordocuments/1714548875773.pdf",
            },
          };
        } else {
          for (const [key, link] of Object.entries(allFiles)) {
            const formData = new FormData();
            formData.append("files", link);
            formData.append("email", JSON.parse(getUserData())?.email);
            const response = await axios.post("/upload-file", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            console.log(response);
            dispatch(
              setProgress({
                progress: ((count + 1) / Object.entries(allFiles).length) * 100,
                fileName: "",
              })
            );
            payload = {
              ...payload,
              [key]: {
                __metadata: { type: "SP.FieldUrlValue" },
                Url: response.data.data[0].public_url,
                Description: response.data.data[0].public_url,
              },
            };
            count++;
          }
        }

        const addResponse = await axios.post(
          "profile/add",
          JSON.stringify(payload),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );

        if (addResponse.status === 201) {
          dispatch(setUser(addResponse.data.data));
          resolve(addResponse);
        }
      } catch (error) {
        console.error("Error during registration process:", error);
        dispatch(setError());
        reject(error);
      }
    });
};

export const userUpdate = (body, lastData) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let changeValueKeys = [];
      for (var ky in body) {
        var Array = Object.entries(lastData);
        let obj = Array.filter((obj) => obj[0] === ky);
        let lastKeyValue =
          obj[0] && (obj[0][1]?.value ? obj[0][1].value : obj[0][1]);
        let currentKeyValue = body[ky]?.value ? body[ky].value : body[ky];
        if (ky === "VendorEmail") {
          body[ky] = JSON.parse(getUserData()).email;
        }
        if (
          ky === "DateOfIncorporation" ||
          ky === "BusinessRegistrationExpiryDate"
        ) {
          lastKeyValue = new Date(lastKeyValue).toLocaleDateString("en-US");
          currentKeyValue = new Date(currentKeyValue).toLocaleDateString(
            "en-US"
          );
        }
        if (
          ky !== "ValidTradeLicenseLink" &&
          ky !== "CommercialRegistryLink" &&
          ky !== "TaxRegistrationCertificateLink" &&
          ky !== "FinancialStatementLink" &&
          ky !== "BankReferenceLetterLink" &&
          ky !== "UltimateOwnerIDLink"
        ) {
          if (currentKeyValue !== lastKeyValue) {
            changeValueKeys.push(ky);
          }
        }
        if (ky === "isRetainageAllowed") {
          if (!body[ky]) {
            body["RetainagePercentage"] = 0;
          }
        } else {
          if (body[ky]) {
            body[ky] = body[ky]?.label ? body[ky].value : body[ky];
          }
        }
      }
      let noCompArr = {
        name: "No Compliance",
        value: intersect_arrays(changeValueKeys, noCompliance).length,
      };
      let apCompArr = {
        name: "AP Compliance",
        value: intersect_arrays(changeValueKeys, apCompliance).length,
      };
      let normalArr = {
        name: "Normal",
        value: intersect_arrays(changeValueKeys, normal).length,
      };

      let normalValue = normalArr.value;
      let apCompValue = apCompArr.value;
      let noCompValue = noCompArr.value;

      let vendorSubmissionType = "Normal";

      if (normalValue > 0) {
        vendorSubmissionType = "Normal";
      } else if (normalValue === 0 && apCompValue > 0) {
        vendorSubmissionType = "AP Compliance";
      } else if (normalValue === 0 && apCompValue === 0 && noCompValue > 0) {
        vendorSubmissionType = "No Compliance";
      }

      body["VendorSubmissionType"] = !body["isCompliant"]
        ? "Normal"
        : vendorSubmissionType;

      body["Status"] = "In Progress";

      let payload = body;
      const files = {
        ValidTradeLicenseLink: body.ValidTradeLicenseLink,
        CommercialRegistryLink: body.CommercialRegistryLink,
        TaxRegistrationCertificateLink: body.TaxRegistrationCertificateLink,
        BankReferenceLetterLink: body.BankReferenceLetterLink,
        UltimateOwnerIDLink: body.UltimateOwnerIDLink,
      };

      const allFiles = body.FinancialStatementLink
        ? {
            ...files,
            FinancialStatementLink: body.FinancialStatementLink,
          }
        : files;

      // const filesToUpload = Object.values(allFiles).filter(
      //   (value) => !value?.__metadata
      // );

      const filesToUpload = [];
      for (var key in allFiles) {
        if (allFiles[key]) {
          if (!allFiles[key]?.__metadata) {
            allFiles[key].key = key;
            filesToUpload.push(allFiles[key]);
          }
        }
      }

      if (filesToUpload.length) {
        let count = 0;
        const promises = filesToUpload.map((link) => {
          const formData = new FormData();
          if (link) {
            formData.append("files", link);
            formData.append("email", JSON.parse(getUserData())?.email);
            return axios
              .post("/upload-file", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                dispatch(
                  setProgress({
                    progress:
                      ((count + 1) / Object.values(allFiles)?.length) * 100,
                    fileName: "",
                  })
                );
                count = count + 1;
                return res;
              });
          }
        });
        Promise.all(promises)
          .then((responses) => {
            responses.forEach((response, index) => {
              const key = filesToUpload[index].key;
              payload = {
                ...payload,
                [key]: {
                  __metadata: {
                    type: "SP.FieldUrlValue",
                  },
                  Url: response.data.data[0].public_url,
                  Description: response.data.data[0].public_url,
                },
              };
            });
            axios
              .post("profile/update", JSON.stringify(payload), {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json; charset=utf-8",
                },
              })
              .then((response) => {
                if (response.status === 201) {
                  dispatch(setUser(response.data.data));
                  resolve(response);
                }
              })
              .catch((error) => {
                if (!error.response.data.success) {
                  dispatch(setError());
                  reject(error);
                }
              });
          })
          .catch((error) => {
            if (!error.response.data.success) {
              dispatch(setError());
              reject(error);
            }
          });
      } else {
        axios
          .post("profile/update", JSON.stringify(payload), {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
          })
          .then((response) => {
            if (response.status === 201) {
              dispatch(setUser(response.data.data));
              resolve(response);
            }
          })
          .catch((error) => {
            if (!error.response.data.success) {
              dispatch(setError());
              reject(error);
            }
          });
      }
    });
};

export const userSubmissionTypeUpdate = async (body) => {
  const res = await axios.post("profile/update", JSON.stringify(body), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  if (res?.status === 201) {
    return res.data?.data?.data;
  }
};

export const getProfile = (user_id) => {
  try {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        axios
          .get("profile/" + user_id)
          .then(async (response) => {
            if (response.status === 200) {
              saveUserData(response.data.data.user);
              if (
                !response.data?.data?.data?.isCompliant &&
                response.data?.data?.data?.VendorSubmissionType !== "Normal"
              ) {
                await userSubmissionTypeUpdate({
                  ...response.data?.data?.data,
                  VendorSubmissionType: "Normal",
                });
              }
              dispatch(setUserFormData(response.data.data));
              resolve(response);
            } else {
              reject(new Error("Error Fetching Profile"));
            }
          })
          .catch((error) => {
            reject(error);
            if (!error?.response?.data?.success) {
              dispatch(setError());
            }
          });
      });
    };
  } catch (err) {
    return err;
  }
};

export const register = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("register", JSON.stringify(body))
        .then((response) => {
          if (response.status === 201) {
            saveUserData(response.data.data.user);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const login = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            dispatch(getProfile(response.data.data.user.user_id));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const loginWithMS = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login-with-ms", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            dispatch(setUserFormData(response.data.data));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const loginWithManager = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login-with-manager", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const accountActivationLink = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("resend-activation-link", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const forgotPassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("forgot-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const resetPassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("reset-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const changePassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("change-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const sendOtp = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("send-otp", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const verifyOtp = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("verify-otp", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const logout = () => {
  destroy();
  window.location.reload(true);
};

export const managerLogout = () => {
  destroy();
  window.location.href = "/admin-portal";
};
