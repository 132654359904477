import { useTheme } from "@emotion/react";
import React from "react";
import { Button, CardContent, CardMedia, Typography } from "@mui/material";
import { iconArrowRight } from "../../assets/images";
import styled from "@emotion/styled";

const HorizontalCard = ({ item }) => {
  const { img, date, title } = item;
  const { palette } = useTheme();

  const Root = styled("div")(({ theme }) => ({
    borderRadius: "0",
    boxShadow: "none",
    borderBottom: `1px solid ${palette.secondary[100]}`,
    display: "flex",
    "& .MuiCardMedia-media": {
      width: "108px",
      borderRadius: "14px",
    },
    "& .MuiButtonBase-root": {
      padding: 0,
      color: palette.primary[500],
      fontFamily: "var(--font-p_r)",
      fontSize: "14px",
      textTransform: "capitalize",
      "& img": {
        transition: "0.3s",
        marginLeft: "10px",
      },
      "&:hover": {
        "& img": {
          marginLeft: "5px",
        },
      },
    },
    "@media(max-width: 375px)": {
      flexWrap: "wrap",
      "& .MuiCardContent-root": {
        padding: "10px 0",
      },
    },
    [theme.breakpoints.up("md")]: {
      background: "transparent",
    },
  }));
  return (
    <Root>
      <CardMedia component="img" alt="green iguana" height="108" image={img} />
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          fontSize="14px"
          color={palette.grey[500]}
          fontFamily="var(--font-p_r)"
          marginBottom="3px"
        >
          {date}
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontSize="16px"
          color={palette.secondary[700]}
          fontFamily="var(--font_et_r)"
          marginBottom="13px"
        >
          {title}
        </Typography>
        <Button size="small">
          Continue reading <img src={iconArrowRight} alt="" />
        </Button>
      </CardContent>
    </Root>
  );
};

export default HorizontalCard;
