import React from "react";
import { Heading, HorizontalCard, ImgMediaCard } from "../components";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import {
  imgNews1,
  imgNews2,
  imgNews3,
  imgNews4,
  imgNews5,
  imgNews6,
  imgNews7,
  imgNews8,
  imgNews9,
} from "../assets/images";
import styled from "@emotion/styled";
const News = () => {
  const { palette } = useTheme();
  const cardData = [
    {
      id: 1,
      date: "10 Mar 2023",
      img: imgNews1,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 2,
      date: "10 Mar 2023",
      img: imgNews2,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 3,
      date: "10 Mar 2023",
      img: imgNews3,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 4,
      date: "10 Mar 2023",
      img: imgNews4,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
  ];
  const horizontalCardData = [
    {
      id: 1,
      date: "10 Mar 2023",
      img: imgNews1,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 2,
      date: "10 Mar 2023",
      img: imgNews5,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 3,
      date: "10 Mar 2023",
      img: imgNews6,
      title: "OLA Energy Announces the Appointment of its new CEO",
      description: "Following a decision on 11 October 2021, the Board of....",
    },
    {
      id: 4,
      date: "10 Mar 2023",
      img: imgNews7,
      title: "OLA Energy Announces the Appointment of its new CEO",
    },
    {
      id: 4,
      date: "10 Mar 2023",
      img: imgNews8,
      title: "OLA Energy Announces the Appointment of its new CEO",
    },
    {
      id: 4,
      date: "10 Mar 2023",
      img: imgNews9,
      title: "OLA Energy Announces the Appointment of its new CEO",
    },
  ];

  const Root = styled("section")(({ theme }) => ({
    padding: "20px 10px",
    "& .left": {
      paddingRight: "0",
      "& .MuiGrid-container": {
        justifyContent: "center",
      },
    },
    "& .right": {
      borderLeft: "none",
      marginTop: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "30px 20px",
      "& .left": {
        paddingRight: "20px",
        "& .MuiGrid-container": {
          justifyContent: "flex-start",
        },
      },
      "& .right": {
        borderLeft: `1px solid ${palette.secondary[100]}`,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .right": {
        padding: "0 20px",
        marginTop: "0",
      },
    },
  }));
  return (
    <Root>
      <Grid container>
        <Grid item lg={8} md={7} sm={12}>
          <Box className="left">
            <Heading title="Latest News" />
            <Grid container spacing="20px">
              {cardData?.map((item, i) => (
                <Grid item xl={4} lg={6} md={6} sm={6} key={i}>
                  <ImgMediaCard item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} md={5} sm={12}>
          <Box className="right">
            <Heading title="Events" />
            <Grid container spacing="30px">
              {horizontalCardData?.map((item, i) => (
                <Grid item sm={12} key={i}>
                  <HorizontalCard item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};

export default News;
