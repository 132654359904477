import React, { useContext, useState } from "react";
import { CustomButton, CustomInput, Heading } from "../components";
import {
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { validationErrors } from "../constants/validationErrors";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../slice/user.slice";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { EMAIL } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../context/languageContext";

const ForgotPassword = () => {
  const { locale } = useContext(LanguageContext);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL, "Invalid email address")
      .required("Email is Required"),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    setLoading(true);
    data.language = locale ? locale : "en";
    dispatch(forgotPassword(data))
      .then((data) => {
        setToast({ type: "success", message: data?.data?.msg });
        navigate("/success?email=sent");
      })
      .catch((err) => {
        setToast({ type: "error", message: err?.data?.errors[0]?.msg });
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const Root = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    "& .MuiInputLabel-root": {
      textAlign: "left",
    },
    "& .MuiButton-root": {
      marginTop: "15px",
    },
    "& .reset": {
      background: palette.grey[200],
      borderRadius: "14px",
      padding: "20px",
      "& .MuiTypography-h2": {
        textAlign: "left",
        paddingBottom: "10px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .reset": {
        padding: "30px",
      },
    },
  }));

  return (
    <>
      {toast?.type && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          itemType="error"
          open={toast?.type}
          autoHideDuration={5000}
          onClose={() => setToast(false)}
        >
          <SnackbarContent
            style={{
              backgroundColor: toast?.type === "success" ? "black" : "red",
            }}
            message={toast?.message}
          />
        </Snackbar>
      )}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Root>
          <Grid
            container
            dir={locale === "ar" ? "rtl" : "ltr"}
            sx={{
              "&[dir|='rtl']": {
                "& .MuiTypography-h2": { textAlign: "right" },
                "& .MuiTypography-body1 a": {
                  marginRight: "10px",
                },
              },
            }}
          >
            <Grid item xl={4} lg={5} md={6} sm={8} xs={11} mx="auto">
              <Box className="reset">
                <Heading
                  title={useIntl().formatMessage({ id: "forgetPassword" })}
                />
                <CustomInput
                  label={useIntl().formatMessage({ id: "enterEmail" })}
                  type="text"
                  placeholder={useIntl().formatMessage({ id: "email" })}
                  name="email"
                  rules={{
                    required: validationErrors.required,
                  }}
                  error={errors?.email?.message}
                  control={control}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "submit" })}
                  size="small"
                  type="submit"
                  full
                  loading={loading}
                />
                <Box sx={{ marginBlock: "20px 0px" }}>
                  <Typography
                    variant="body1"
                    display="flex"
                    justifyContent="center"
                    gap="10px"
                    flexWrap="wrap"
                    align="center"
                  >
                    <FormattedMessage id="remeberPasswordLogin" />
                    <Link to="/?sidebar=true">
                      <FormattedMessage id="login" />
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Root>
      </form>
    </>
  );
};

export default ForgotPassword;
