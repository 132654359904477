import { createSlice } from "@reduxjs/toolkit";

import axios from "../services/axios";

const initialState = {
  loading: true,
  error: false,
  adminManager: [],
};

const adminManagerSlice = createSlice({
  name: "adminManagerSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setAdminManager: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.adminManager = payload;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setAdminManager, setError } =
  adminManagerSlice.actions;

const { reducer } = adminManagerSlice;
export default reducer;

export const getAdminManager = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/managers")
        .then((response) => {
          if (response.status === 200) {
            dispatch(setAdminManager(response.data.data));
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const changeAdminManagerPermission = (id, body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put("admin/manager/permission/" + id, JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const addNewUser = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/manager", JSON.stringify(body))
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const updateUser = (id, body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put("admin/manager/" + id, JSON.stringify(body))
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const deleteUser = (id, body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("admin/manager/" + id)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const verifyEmail = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("verify-email", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};
