export const saveToken = (token) => {
  sessionStorage.setItem("access_token", token);
};

export const getToken = () => {
  // console.log('Getting User Token From Local Storage');
  return sessionStorage.getItem("access_token");
};

export const removeToken = () => {
  sessionStorage.removeItem("access_token");
};

export const saveUserData = (data) => {
  sessionStorage.setItem("ola_user_data", JSON.stringify(data));
};

export const getUserData = () => {
  return sessionStorage.getItem("ola_user_data");
};

export const removeUserData = () => {
  sessionStorage.removeItem("ola_user_data");
};

export const destroy = () => {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("ola_user_data");
};
