import React from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export default function Wrapper({ children, transparent, padding, margin }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: transparent ? "transparent" : theme.palette.grey[200],
        padding: padding ? "" : "20px",
        borderRadius: "14px",
        margin: "10px",
        [theme.breakpoints.up("sm")]: {
          padding: padding ? "" : "30px",
          margin: margin ? "" : "30px",
        },
      }}
    >
      {children}
    </Box>
  );
}
