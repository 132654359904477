import styled from "@emotion/styled";
import { Breadcrumbs, Grid, Typography, Box, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import bannerImg from "../assets/images/inner-banner.jpg";
import iconLogo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../context/languageContext";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
const BreadCrumb = ({ menuOpen, show }) => {
  const { palette } = useTheme();
  const { locale } = useContext(LanguageContext);

  const Root = styled("section")(({ theme }) => ({
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    height: "200px",

    "& .banner": {
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transform: locale === "ar" ? "scaleX(-100%)" : "inherit",
    },
    "& .MuiBreadcrumbs-root": {
      "& .MuiBreadcrumbs-ol": {
        "& li": {
          color: palette.grey[200],
          fontFamily: "var(--font_et_r)",
          fontWeight: "400",
          fontSize: "20px",
          "& a": {
            fontFamily: "var(--font_et_r)",
            fontWeight: "400",
            fontSize: "20px",
            color: palette.grey[200],
          },
        },
        "& .MuiLink-root": {},
      },
    },
    "& .logo": {
      paddingBottom: "10px",
      maxWidth: "150px",
    },
    "& .close": {
      position: "absolute",
      top: "10px",
      right: "10px",
      transition: "all 0.3s",
      width: "40px",
      height: "40px",
      padding: "0",
      display: "grid",
      placeItems: "center",
      color: theme.palette.grey[200],
      background: theme.palette.primary[500],
      "&:hover": {
        color: theme.palette.grey[200],
        background: theme.palette.primary[500],
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .logo": { paddingBottom: "0" },
      "& .close": { display: "none" },
    },
  }));
  return (
    <Root>
      <img src={bannerImg} alt="" className="banner" />
      <IconButton className="close" onClick={menuOpen}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Container maxWidth="fluid">
        <Grid
          container
          alignItems="flex-end"
          marginBottom="20px"
          position="relative"
        >
          <Grid item lg={2} md={3} sm={3}>
            <Link to="/">
              <img src={iconLogo} alt="" className="logo" />
            </Link>
          </Grid>
          <Grid item lg={10} md={9} sm={9}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/dashboard">
                <FormattedMessage id="vendor" />
              </Link>
              <Typography>
                <Link to="/dashboard/registration">
                  <FormattedMessage id="registrationForm" />
                </Link>
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          marginBottom: "120px",
          marginRight: "30px",
          color: palette.grey[200],
        }}
      ></Box>
    </Root>
  );
};

export default BreadCrumb;
