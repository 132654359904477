import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  CustomButton,
  CustomInput,
  Heading,
  InputPassword,
} from "../components";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { validationErrors } from "../constants/validationErrors";
import { login, register } from "../slice/user.slice";
import { showToast } from "../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../context/languageContext";
import { EMAIL } from "../constants";

const Login = ({ show, setShow }) => {
  const passwordNotMatch = useIntl().formatMessage({
    id: "passwordNotMatch",
  });
  const { locale } = useContext(LanguageContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [authMode, setAuthMode] = useState("Login");
  const navigate = useNavigate();
  const [toast, setToast] = useState(false);
  let confirmPasswordTitle = useIntl().formatMessage({ id: "confirmPassword" });
  let keepMeLogintext = useIntl().formatMessage({ id: "keepMeLoggedIn" });

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({ mode: "onTouched" });

  const handleLogin = (data) => {
    setLoading(true);
    data.language = locale;
    dispatch(login(data))
      .then((res) => {
        setLoading(false);
        setToast({ type: "success", message: res?.data?.data?.msg });
        res?.data?.data?.user?.user_type.toString() === "vendor"
          ? navigate(`dashboard/user/${res?.data?.data?.user?.user_id}?`)
          : navigate(`dashboard/profile-management`);
      })
      .catch((err) => {
        setLoading(false);
        setToast({
          type: "error",
          message: err?.data?.errors[0]?.msg,
        });
      });
  };

  const handleRegister = (data) => {
    setLoading(true);
    data.language = locale ? locale : "en";
    data.confirm_password = data.confirmPassword;
    dispatch(register(data))
      .then((res) => {
        setLoading(false);
        setToast({ type: "success", message: res?.data?.data?.msg });
        setAuthMode("Login");
      })
      .catch((err) => {
        setLoading(false);
        setToast({
          type: "error",
          message: err?.data?.errors[0]?.msg,
        });
      });
  };

  // const loginHandler = () => {
  //   instance
  //     .loginPopup({
  //       scopes: ["email"],
  //       prompt: "select_account",
  //     })
  //     .then((result) => {
  //       if (result.accessToken) {
  //         var body = {
  //           email: result.account.username,
  //           access_token: result.accessToken,
  //         };
  //         dispatch(loginWithMS(body))
  //           .then((res) => {
  //             setToast({ type: "success", message: res?.data?.data?.msg });
  //             res?.data?.data?.user?.user_type === "superadmin"
  //               ? navigate(`dashboard/`)
  //               : navigate(`dashboard/user/${res?.data?.data?.user?.user_id}?`);
  //           })
  //           .catch((err) => {
  //             setToast({
  //               type: "error",
  //               message: err?.data?.errors[0]?.msg,
  //             });
  //           });
  //       } else {
  //         setToast({
  //           type: "error",
  //           message: result.err,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       // setToast({
  //       //   type: "error",
  //       //   message: JSON.parse(JSON.stringify(err)).errorMessage,
  //       // });
  //     });
  // };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box
        dir={locale === "ar" ? "rtl" : "ltr"}
        sx={{
          transition: "all 0.3s",
          position: "fixed",
          top: "0",
          background: theme.palette.grey[200],
          right: 0,
          height: "100vh",
          width: "100%",
          transform: show ? "translateX(0%)" : "translateX(100%)",
          zIndex: "9999",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            width: "500px",
          },
          "& .button_space": {
            marginBottom: "20px",
          },
          "& .close": {
            position: "absolute",
            top: "10px",
            right: "10px",
            transition: "all 0.3s",
            color: theme.palette.grey[200],
            background: theme.palette.primary[500],
          },
          "&[dir|='rtl']": {
            "& .MuiFormControlLabel-root": { marginRight: "0" },
            "& .loginWithMicrosoft img": {
              marginLeft: "10px",
            },
            "& .MuiTypography-h6 a": {
              marginRight: "10px",
            },
          },
        }}
      >
        <Box
          component="div"
          sx={{
            width: "100%",
            paddingInline: "20px",
            [theme.breakpoints.up("md")]: {
              paddingInline: "40px",
            },
            "& .MuiTypography-body1": {
              color: theme.palette.secondary[500],
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "var(--font-p_m)",
              "& a": {
                color: theme.palette.primary[500],
              },
            },
            "& .MuiTypography-h6": {
              textAlign: "center",
              color: theme.palette.secondary[500],
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "var(--font-p_m)",
              "& a": {
                color: theme.palette.primary[500],
              },
            },
          }}
        >
          <IconButton className="close" onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
          <Heading
            title={useIntl().formatMessage({
              id: authMode === "Login" ? "login" : "register",
            })}
          />
          <Box
            component="form"
            onSubmit={handleSubmit(
              authMode === "Login" ? handleLogin : handleRegister
            )}
          >
            <CustomInput
              label={useIntl().formatMessage({ id: "email" })}
              type="email"
              placeholder={useIntl().formatMessage({ id: "email" })}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: validationErrors.required,
                },
                pattern: { value: EMAIL, message: validationErrors.email },
              }}
              error={errors?.email?.message}
              control={control}
            />
            <InputPassword
              label={useIntl().formatMessage({ id: "password" })}
              placeholder={useIntl().formatMessage({ id: "password" })}
              name="password"
              error={errors?.password?.message}
              rules={{
                required: {
                  value: true,
                  message: validationErrors.required,
                },
              }}
              control={control}
            />
            {authMode === "Register" ? (
              <InputPassword
                label={confirmPasswordTitle}
                placeholder={confirmPasswordTitle}
                name="confirmPassword"
                error={errors?.confirmPassword?.message}
                rules={{
                  required: {
                    value: true,
                    message: validationErrors.required,
                  },
                  validate: {
                    matchesPassword: (value) =>
                      value === watch("password") || passwordNotMatch,
                  },
                }}
                control={control}
              />
            ) : (
              ""
            )}
            {authMode === "Login" ? (
              <Box sx={{ marginBlock: "20px 0px" }}>
                <Typography variant="body1" align="right">
                  <Link to="/forgot-password">
                    <FormattedMessage id="forgetPassword" />
                  </Link>
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {authMode === "Login" ? (
              <Box>
                <Typography variant="h5">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={keepMeLogintext}
                    />
                  </FormGroup>
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box sx={{ marginBlock: "40px 20px" }}>
              <CustomButton
                title={useIntl().formatMessage({
                  id: authMode === "Login" ? "login" : "register",
                })}
                full
                size="large"
                className="button_space"
                type="submit"
                loading={loading}
                disabled={loading}
                // onClick={() => handleLogin()}
              />
              {/* <CustomButton title="Me" full /> */}
            </Box>
            {/* <Box
              sx={{ marginBlock: "20px 20px" }}
              className="loginWithMicrosoft"
            >
              <div
                onClick={() => loginHandler()}
                style={{
                  borderRadius: "10px",
                  fontWeight: "500",
                  width: "100%",
                  textTransform: "capitalize",
                  fontFamily: "var(--font-p_sb)",
                  background: "#ffffff",
                  color: "#5e5e5e",
                  textAlign: "center",
                  padding: "12px",
                  border: "1px solid #8c8c8c",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={MicrosoftIcon}
                  alt="MicrosoftIcon"
                  style={{ width: "28px", marginRight: "12px" }}
                />
                <FormattedMessage id="loginWithMicrosoft" />
              </div>
            </Box> */}
            <Box>
              <Typography variant="h6">
                <FormattedMessage
                  id={authMode === "Login" ? "haveAccount" : "alreadyAccount"}
                />
                {authMode === "Login" ? (
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setAuthMode("Register");
                    }}
                  >
                    <FormattedMessage id="register" />
                  </a>
                ) : (
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setAuthMode("Login");
                    }}
                  >
                    <FormattedMessage id="login" />
                  </a>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
