/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton, CustomInput, Wrapper } from "../../components";
import { useTheme } from "@emotion/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils";
import { validationErrors } from "../../constants/validationErrors";
import { userUpdate } from "../../slice/user.slice";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  currencyOptions,
  currencyOptionsAr,
  currencyOptionsFr,
  documentDeliveryMethodOptions,
  documentDeliveryMethodOptionsAr,
  documentDeliveryMethodOptionsFr,
  preferredMethodOfPaymentOptions,
  preferredMethodOfPaymentOptionsAr,
  preferredMethodOfPaymentOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const PaymentTerms = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const { palette } = useTheme();
  const userFormData = useSelector((state) => state.user?.userFormData);
  const [state, setState] = useAppState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [currencyList, setCurrencyList] = useState(currencyOptions);
  const [documentDeliveryMethodList, setDocumentDeliveryMethodList] = useState(
    documentDeliveryMethodOptions
  );
  const [preferredMethodOfPaymentList, setPreferredMethodOfPaymentList] =
    useState(preferredMethodOfPaymentOptions);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });

  const validationSchema = Yup.object().shape({
    PaymentCurrency: Yup.object().required(
      useIntl().formatMessage({ id: "paymentCurrencyisRequired" })
    ),
    DocumentDeliveryMethod: Yup.object().required(
      useIntl().formatMessage({ id: "documentDeliveryMethodisRequired" })
    ),
    PreferredMethodOfPayment: Yup.object().required(
      useIntl().formatMessage({ id: "preferredMethodofPaymentisRequired" })
    ),
    isRetainageAllowed: Yup.boolean(),
    RetainagePercentage: Yup.string().when("isRetainageAllowed", {
      is: true,
      then: () =>
        Yup.number()
          .typeError("Retainage Percentage should be a number")
          .required("Retainage Percentage is Required")
          .max(100, "Retainage Percentage should be less than 100")
          .min(1, "Retainage Percentage should be greater than 1"),
    }),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userFormData?.PaymentCurrency) {
      reset({
        PaymentCurrency: currencyList.length
          ? currencyList.filter(
              (item) => item.value === userFormData?.PaymentCurrency
            )[0]
          : "",
        DocumentDeliveryMethod: documentDeliveryMethodList.length
          ? documentDeliveryMethodList.filter(
              (item) => item.value === userFormData?.DocumentDeliveryMethod
            )[0]
          : "",
        PreferredMethodOfPayment: preferredMethodOfPaymentList.length
          ? preferredMethodOfPaymentList.filter(
              (item) => item.value === userFormData?.PreferredMethodOfPayment
            )[0]
          : "",
        isRetainageAllowed: userFormData?.isRetainageAllowed,
        RetainagePercentage: userFormData?.RetainagePercentage || 0,
      });
    }
    if (locale == "ar") {
      setCurrencyList(currencyOptionsAr);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptionsAr);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptionsAr);
    } else if (locale == "fr") {
      setCurrencyList(currencyOptionsFr);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptionsFr);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptionsFr);
    } else {
      setCurrencyList(currencyOptions);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptions);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptions);
    }
  }, [
    userFormData,
    currencyList,
    documentDeliveryMethodList,
    preferredMethodOfPaymentList,
  ]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    setState({ ...state, ...finalData });
    onNext();
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };
  const isRetainageAllowed = watch("isRetainageAllowed");

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCurrencyList(currencyOptionsAr);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptionsAr);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptionsAr);
    } else if (lang == "fr") {
      setCurrencyList(currencyOptionsFr);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptionsFr);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptionsFr);
    } else {
      setCurrencyList(currencyOptions);
      setDocumentDeliveryMethodList(documentDeliveryMethodOptions);
      setPreferredMethodOfPaymentList(preferredMethodOfPaymentOptions);
    }
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="paymentCurrency" />
                    </InputLabel>
                    <Controller
                      name="PaymentCurrency"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={currencyList}
                        />
                      )}
                    />
                    {errors?.PaymentCurrency?.message && (
                      <FormHelperText error>
                        {errors?.PaymentCurrency?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="documentDeliveryMethod" />
                    </InputLabel>
                    <Controller
                      name="DocumentDeliveryMethod"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={documentDeliveryMethodList}
                        />
                      )}
                    />
                    {errors?.DocumentDeliveryMethod?.message && (
                      <FormHelperText error>
                        {errors?.DocumentDeliveryMethod?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="preferredPaymentMethod" />
                    </InputLabel>
                    <Controller
                      name="PreferredMethodOfPayment"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={preferredMethodOfPaymentList}
                        />
                      )}
                    />
                    {errors?.PreferredMethodOfPayment?.message && (
                      <FormHelperText error>
                        {errors?.PreferredMethodOfPayment?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Controller
                    name="isRetainageAllowed"
                    control={control}
                    render={({ value, field: { onChange } }) => (
                      <FormControlLabel
                        name="isRetainageAllowed"
                        onChange={onChange}
                        control={
                          <Checkbox
                            checked={isRetainageAllowed ? true : false}
                            onChange={onChange}
                          />
                        }
                        label={useIntl().formatMessage({
                          id: "retainageAllowed",
                        })}
                      />
                    )}
                  />
                </Grid>
                {isRetainageAllowed && (
                  <Grid item lg={12} sm={12} xs={12}>
                    <CustomInput
                      rules={{
                        required: validationErrors.required,
                      }}
                      label={useIntl().formatMessage({
                        id: "retainagePercentage",
                      })}
                      type="number"
                      placeholder={useIntl().formatMessage({
                        id: "enterRetainagePercentage",
                      })}
                      name="RetainagePercentage"
                      error={errors?.RetainagePercentage?.message}
                      control={control}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  onClick={onPrev}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  size="small"
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                />
                {/* {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleSubmit((data) => onSubmit(data, "update"))}
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PaymentTerms;
