import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/axios";
const initialState = {
  loading: true,
  error: false,
  adminProfile: [],
};

const adminProfileSlice = createSlice({
  name: "adminProfileSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setAdminProfile: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.adminProfile = payload;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setAdminProfile, setError } =
  adminProfileSlice.actions;

const { reducer } = adminProfileSlice;
export default reducer;

export const getAdminProfile = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/profiles")
        .then((response) => {
          if (response.status === 200) {
            dispatch(setAdminProfile(response.data.data));
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const changeAdminProfileStatus = (id, body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put("admin/profile/" + id, JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
          }
        });
    });
  };
};
