import React from "react";
import { CustomButton } from "../components";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { FormattedMessage, useIntl } from "react-intl";

const Success = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailSent = searchParams.get("email");

  const Root = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    "& .MuiIconButton-root": {
      color: palette.grey[200],
      background: palette.primary[500],
      width: "60px",
      height: "60px",
      "& svg": {
        width: "30px",
        height: "30px",
      },
    },
    "& .success": {
      background: palette.grey[200],
      borderRadius: "14px",
      padding: "20px",
      "& .MuiTypography-h5": {
        fontSize: "18px",
        fontWeight: "600",
        fontFamily: "var(--font-p_sb)",
        color: palette.secondary[700],
        marginBlock: "15px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "20px",
      "& .success": {
        padding: "30px",
        fontSize: "20px",
        "& .MuiTypography-h5": {
          fontSize: "20px",
        },
      },
      "& .MuiIconButton-root": {
        width: "80px",
        height: "80px",
        "& svg": {
          width: "40px",
          height: "40px",
        },
      },
    },
  }));

  return (
    <Root>
      <Grid container>
        <Grid item xl={4} lg={5} md={6} xs={11} mx="auto">
          <Box className="success">
            <IconButton>
              <CheckIcon />
            </IconButton>
            <Typography variant="h5">
              {emailSent ? (
                <FormattedMessage id="resetPasswordSentEmailAddress" />
              ) : (
                <>
                  <FormattedMessage id="successfullySubmittedForm" />
                  <br />
                </>
              )}
            </Typography>
            <CustomButton
              title={useIntl().formatMessage({ id: "login" })}
              size="small"
              onClick={() =>
                navigate({
                  pathname: "/",
                  search: "?sidebar=true",
                })
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Success;
