import { configureStore } from "@reduxjs/toolkit";
import { authReducer, userReducer } from "./slice";

// ***************************************************

const reducer = {
  auth: authReducer,
  user: userReducer,
};

// ***************************************************

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
