import React, { useContext, useState, useEffect } from "react";
import { CustomButton, CustomInput, Heading } from "../components";
import {
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { validationErrors } from "../constants/validationErrors";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword } from "../slice/user.slice";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../context/languageContext";
import { getUserData } from "../services/authService";

const ResetPassword = () => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const { palette } = useTheme();
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const language = searchParams.get("language");
  const navigate = useNavigate();
  let yourPasswordChanged = useIntl().formatMessage({
    id: "yourPasswordChanged",
  });

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required(
      useIntl().formatMessage({ id: "oldPassword" }) + " is Required"
    ),
    password: Yup.string().required(
      useIntl().formatMessage({ id: "password" }) + " is Required"
    ),
    confirmPassword: Yup.string()
      .required(
        useIntl().formatMessage({ id: "confirmPassword" }) + " is Required"
      )
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm Password must be same as Password"
      ),
  });

  useEffect(() => {
    selectLanguage(language);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    const payload = {
      oldpassword: data.oldpassword,
      password: data.password,
      user_id: JSON.parse(getUserData()).user_id,
      language: locale,
    };
    dispatch(changePassword(payload))
      .then((res) => {
        setToast({
          type: "success",
          message: yourPasswordChanged,
        });
        navigate("/success");
      })
      .catch((err) => {
        setLoading(false);
        setToast({ type: "error", message: err?.data?.errors[0]?.msg });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoBack = () => {
    window.location.replace(
      "/dashboard/user/" + JSON.parse(getUserData()).user_id
    );
  };

  const Root = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    "& .MuiInputLabel-root": {
      textAlign: "left",
    },
    "& .MuiButton-root": {
      marginTop: "15px",
    },
    "& .reset": {
      background: palette.grey[200],
      borderRadius: "14px",
      padding: "20px",
      "& .MuiTypography-h2": {
        textAlign: "left",
        paddingBottom: "10px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .reset": {
        padding: "30px",
      },
    },
  }));

  return (
    <>
      {toast?.type && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          itemType="error"
          open={toast?.type}
          autoHideDuration={5000}
          onClose={() => setToast(false)}
        >
          <SnackbarContent
            style={{
              backgroundColor: toast?.type === "success" ? "black" : "red",
            }}
            message={toast?.message}
          />
        </Snackbar>
      )}
      <Root>
        <Grid
          container
          dir={locale === "ar" ? "rtl" : "ltr"}
          sx={{
            "&[dir|='rtl']": {
              "& .MuiTypography-h2": { textAlign: "right" },
              "& .MuiTypography-body1 a": {
                marginRight: "10px",
              },
            },
          }}
        >
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11} mx="auto">
            <Box className="reset">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Heading
                  title={useIntl().formatMessage({ id: "changePassword" })}
                />
                <CustomInput
                  label={useIntl().formatMessage({ id: "enterOldPassword" })}
                  type={oldPasswordVisible ? "text" : "password"}
                  placeholder={useIntl().formatMessage({ id: "oldPassword" })}
                  name="oldpassword"
                  rules={{
                    required: validationErrors.required,
                  }}
                  error={errors?.oldpassword?.message}
                  control={control}
                  showPassword={oldPasswordVisible}
                  handleClickShowPassword={() => {
                    setOldPasswordVisible(!passwordVisible);
                  }}
                  isPassword
                />
                <CustomInput
                  label={useIntl().formatMessage({ id: "enterNewPassword" })}
                  type={passwordVisible ? "text" : "password"}
                  placeholder={useIntl().formatMessage({ id: "newPassword" })}
                  name="password"
                  rules={{
                    required: validationErrors.required,
                  }}
                  error={errors?.password?.message}
                  control={control}
                  showPassword={passwordVisible}
                  handleClickShowPassword={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                  isPassword
                />
                <CustomInput
                  label={useIntl().formatMessage({ id: "confirmPassword" })}
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder={useIntl().formatMessage({
                    id: "enterConfirmPassword",
                  })}
                  name="confirmPassword"
                  rules={{
                    required: validationErrors.required,
                  }}
                  error={errors?.confirmPassword?.message}
                  control={control}
                  showPassword={confirmPasswordVisible}
                  handleClickShowPassword={() => {
                    setConfirmPasswordVisible(!confirmPasswordVisible);
                  }}
                  isPassword
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "submit" })}
                  size="small"
                  type="submit"
                  full
                  loading={loading}
                />
              </form>
              <Box sx={{ marginBlock: "20px 0px" }}>
                <Typography variant="body1" align="center">
                  <Link
                    onClick={() => {
                      handleGoBack();
                    }}
                  >
                    <FormattedMessage id="goBack" />
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Root>
    </>
  );
};

export default ResetPassword;
