import React, { useState } from "react";
import Select, { components } from "react-select";
import { Box, Modal, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { affiliateOptions } from "../utils";
import CustomButton from "./Button";

const ManagerPermission = ({ manager, handleChangePermission }) => {
  const [isOpen, setIsOpen] = useState(false);

  const parsedAffiliates = manager?.permission?.[0]?.affiliates
    ? JSON.parse(manager?.permission?.[0]?.affiliates)
    : [];

  const [selectedAffiliates, setSelectedAffiliates] = useState(
    affiliateOptions.filter((x) => parsedAffiliates.includes(x.value))
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
  };

  return (
    <>
      <CustomButton
        title="Select Permission"
        sx={{ borderRadius: "5px !important", padding: "5px 15px" }}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        open={isOpen}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          setIsOpen(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            variant="h3"
            marginBottom="15px"
            fontWeight="700"
            fontSize="23px !important"
          >
            <FormattedMessage id="selectCountry" />
          </Typography>
          <Select
            isClearable
            isMulti
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isSearchable
            name="country"
            options={affiliateOptions}
            value={selectedAffiliates}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={(e) => {
              setSelectedAffiliates(e);
            }}
            closeMenuOnSelect={false}
            components={{ MultiValue }}
            menuShouldScrollIntoView={false}
          />
          <Box display="flex" alignItems="center" gap="10px" marginTop="20px">
            <CustomButton
              title={useIntl().formatMessage({ id: "save" })}
              onClick={() => {
                handleChangePermission(manager.id, selectedAffiliates);
                setIsOpen(false);
              }}
            />
            <CustomButton
              title={useIntl().formatMessage({ id: "cancel" })}
              onClick={() => {
                setSelectedAffiliates(
                  affiliateOptions.filter((x) =>
                    parsedAffiliates.includes(x.value)
                  )
                );
                setIsOpen(false);
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManagerPermission;

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 5;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#0171fa",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "11px",
    padding: "4px 6px",
    fontWeight: "600",
    order: 99,
    cursor: "pointer",
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <Box style={style} title={title}>
      {label}
    </Box>
  );
};
