import React, { useEffect, useState } from "react";
import { CustomButton, CustomInput, Heading, Wrapper } from "../components";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Select, { components } from "react-select";
import { Controller, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  addNewUser,
  changeAdminManagerPermission,
  deleteUser as deleteUserAction,
  getAdminManager,
  updateUser,
} from "../slice/adminManager.slice";
import ManagerPermission from "../components/ManagerPermission";
import { affiliateOptions, showToast } from "../utils";
import { validationErrors } from "../constants/validationErrors";
import { EMAIL } from "../constants";

const UserManagement = () => {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [loader, setLoader] = useState(true);
  const [addNewManager, setAddNewManager] = useState(false);
  const [deleteUser, setDeleteUser] = useState({ value: false, id: "" });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(getAdminManager()).then((data) => {
      setRows(data);
      setRows(
        data.map((item) => {
          return {
            ...item,
            id: item.user_id,
          };
        })
      );
      setLoader(false);
    });
  };

  const getAfflliate = (affiliates) => {
    let labels = [];
    let affiliatesArr = JSON.parse(affiliates);
    if (affiliatesArr.length > 0) {
      for (var i = 0; i < affiliatesArr.length; i++) {
        var nameArray = affiliateOptions.map(function (el) {
          if (el.value === affiliatesArr[i]) {
            labels.push(el.label);
          }
        });
      }
    }
    if (labels.length) {
      return labels.toString();
    } else {
      return;
    }
  };

  const columns = [
    {
      field: "user_id",
      headerName: "User Id",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "user_type",
      headerName: "User Type",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "afflliates",
      headerName: "Afflliates",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (params) => (
        <>{getAfflliate(params?.row?.permission?.[0]?.affiliates)}</>
      ),
    },
    {
      field: "permission",
      headerName: "Permission",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (params) => (
        <>
          {
            <ManagerPermission
              manager={params?.row}
              handleChangePermission={handleChangePermission}
            />
          }
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <>
          <Select
            name="status"
            options={userStatusOptions}
            value={
              userStatusOptions.filter(
                (item) => item?.value === params?.row?.status
              )[0]
            }
            onChange={(e) => {
              handleUserUpdate(params?.row?.user_id, e?.value);
            }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <>
          {
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                setDeleteUser({ value: true, id: params?.row?.user_id })
              }
            />
          }
        </>
      ),
    },
  ];

  const userStatusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const handleUserUpdate = (id, status) => {
    setLoader(true);
    const body = {
      status,
    };
    dispatch(updateUser(id, body))
      .then((res) => {
        setToast({ type: "success", message: res.data.msg });
        init();
      })
      .catch((err) => {
        setToast({ type: "error", message: err?.data?.errors?.[0]?.msg });
        setLoader(false);
      });
  };

  const handleChangePermission = (id, selectedAffiliate) => {
    setLoader(true);
    const body = {
      affiliates: selectedAffiliate.map((item) => item.value),
    };
    dispatch(changeAdminManagerPermission(id, body))
      .then((res) => {
        init();
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
  };

  const onSubmit = (data) => {
    setLoader(true);
    const body = {
      ...data,
      affiliates: data?.affiliates?.map((item) => item.value),
    };
    dispatch(addNewUser(body))
      .then((res) => {
        setToast({ type: "success", message: res.data.msg });
        setAddNewManager(false);
        reset();
        init();
      })
      .catch((err) => {
        setToast({ type: "error", message: err?.data?.errors?.[0]?.msg });
        setLoader(false);
      });
  };

  const onDeleteUser = () => {
    setLoader(true);
    dispatch(deleteUserAction(deleteUser?.id))
      .then((res) => {
        setToast({ type: "success", message: res.data.msg });
        setDeleteUser({ value: false, id: null });
        init();
      })
      .catch((err) => {
        setToast({ type: "error", message: err?.data?.errors?.[0]?.msg });
        setLoader(false);
      });
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}

      <Modal
        open={deleteUser?.value}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          setAddNewManager(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h6">
            Are you sure you want to delete this user?
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            marginTop="20px"
          >
            <CustomButton
              title={useIntl().formatMessage({ id: "delete" })}
              type="submit"
              loading={loader}
              onClick={onDeleteUser}
            />
            <CustomButton
              title={useIntl().formatMessage({ id: "cancel" })}
              onClick={() => {
                setDeleteUser({ value: false, id: null });
              }}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={addNewManager}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          setAddNewManager(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={style}>
            <CustomInput
              label={useIntl().formatMessage({ id: "enterEmail" })}
              type="text"
              placeholder={useIntl().formatMessage({ id: "email" })}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: validationErrors.required,
                },
                pattern: { value: EMAIL, message: validationErrors.email },
              }}
              error={errors?.email?.message}
              control={control}
            />

            <Box component="div" className="form-group">
              <InputLabel shrink htmlFor="bootstrap-input">
                <FormattedMessage id="selectCountry" />
              </InputLabel>
              <FormControl>
                <Controller
                  name="affiliates"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: validationErrors.required,
                    },
                  }}
                  render={({ field: { onChange, value = [], ref } }) => (
                    <Select
                      isClearable
                      isMulti
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      isSearchable
                      name="affiliates"
                      options={affiliateOptions}
                      value={value}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      onChange={(e) => {
                        onChange(e);
                      }}
                      closeMenuOnSelect={false}
                      components={{ MultiValue }}
                      menuShouldScrollIntoView={false}
                    />
                  )}
                />
                {errors?.affiliates?.message && (
                  <FormHelperText error>
                    {errors?.affiliates?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box display="flex" alignItems="center" gap="10px" marginTop="20px">
              <CustomButton
                title={useIntl().formatMessage({ id: "save" })}
                type="submit"
                loading={loader}
              />
              <CustomButton
                title={useIntl().formatMessage({ id: "cancel" })}
                onClick={() => {
                  reset({});
                  setAddNewManager(false);
                }}
              />
            </Box>
          </Box>
        </form>
      </Modal>

      <Wrapper>
        {toast?.type && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            itemType="error"
            open={toast?.type}
            autoHideDuration={5000}
            onClose={() => setToast(false)}
          >
            <SnackbarContent
              style={{
                backgroundColor: toast?.type === "success" ? "black" : "red",
              }}
              message={toast?.message}
            />
          </Snackbar>
        )}
        <Heading title="User Management">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              title="Add new manager"
              sx={{
                borderRadius: "5px !important",
                padding: "5px 15px",
              }}
              onClick={() => setAddNewManager(true)}
            />
          </Box>
        </Heading>
        <Box>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            loading={loader}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      </Wrapper>
    </>
  );
};

export default UserManagement;

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 5;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#0171fa",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "11px",
    padding: "4px 6px",
    fontWeight: "600",
    order: 99,
    cursor: "pointer",
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <Box style={style} title={title}>
      {label}
    </Box>
  );
};
