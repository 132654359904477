import React from "react";
import { Button, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const Root = styled("div")(({ theme, full }) => ({
  "& .MuiButton-root": {
    borderRadius: "10px",
    fontWeight: "500",
    width: full && "100%",
    textTransform: "capitalize",
    fontFamily: "var(--font-p_sb)",
    background: theme.palette.primary[500],
    color: theme.palette.grey[200],
  },
  "& .MuiButton-sizeLarge": {
    fontSize: "18px",
    padding: "12px 35px",
    [theme.breakpoints.up("md")]: {
      padding: "12px 90px",
      fontSize: "20px",
    },
  },
  "& .MuiButton-sizeSmall": {
    padding: "8px 30px",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 35px",
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px 60px",
      fontSize: "16px",
    },
  },
  "& .MuiButton-outlined": {
    background: "transparent",
    borderColor: theme.palette.secondary[300],
    color: theme.palette.secondary[900],
  },
}));

const CustomButton = ({
  title,
  onClick,
  className,
  type,
  size,
  full,
  disable = false,
  outline,
  loading,
  ...rest
}) => {
  return (
    <Root full={full} className={className}>
      <Button
        variant={`${outline ? "outlined" : "contained"}`}
        type={type}
        disabled={disable}
        size={size}
        onClick={onClick}
        {...rest}
      >
        {loading ? <CircularProgress size={24} /> : title}
      </Button>
    </Root>
  );
};

export default CustomButton;
