import { Grid, Snackbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CustomSelect, Header } from '../components'
import { CustomButton } from '../components'
import bannerImg from '../assets/images/banner.jpg'
import Login from './login'
import { useTheme } from '@emotion/react'
import { LanguageContext } from '../context/languageContext'
import { useSearchParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { verifyEmail } from '../slice/adminManager.slice'

const Home = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { selectLanguage } = useContext(LanguageContext)
  const sidebar = searchParams.get('sidebar') === 'true'
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const language = searchParams.get('language')
  const { palette } = useTheme()
  const [show, setShow] = useState(sidebar)
  const modalShow = () => {
    setShow(!show)
  }

  useEffect(() => {
    let token = searchParams.get('token')
    if (token) {
      var payload = {
        token: token,
      }
      dispatch(verifyEmail(payload))
        .then((res) => {
          setIsEmailVerified(true)
        })
        .catch((err) => {
          console.log("verify email", err)
        })
    }
    let languageEdit = language !== 'null' ? language : 'en'
    console.log(languageEdit)
    selectLanguage(languageEdit)
  }, [])

  return (
    <Fragment>
      {isEmailVerified && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          itemType="error"
          open={isEmailVerified}
          autoHideDuration={5000}
          message="Email has been verified"
          onClose={() => setIsEmailVerified(false)}
        ></Snackbar>
      )}
      <Header />
      <Box sx={{ display: show && 'flex' }}>
        <Box
          component="section"
          sx={{
            minHeight: '100vh',
            width: '100%',
            display: 'grid',
            placeItems: 'center',
            background: `linear-gradient(125.73deg, ${palette.secondary[800]} 11.48%, ${palette.grey[300]} 104.84%)`,
            '& .MuiTypography-root': {
              marginBottom: '10px',
            },
            '& img': {
              position: 'absolute',
              left: '0',
              top: '0',
              width: '100%',
              height: '100%',
              zIndex: -1,
            },
          }}
        >
          <img src={bannerImg} alt="" />
          <Grid container>
            <Grid item lg={5} mx="auto">
              <Box component="div" textAlign="center">
                <Box component="div" marginBottom="30px">
                  <Typography
                    variant="h1"
                    color={palette.grey[200]}
                    fontWeight="600"
                    fontFamily="var(--font_et_r)"
                  >
                    <FormattedMessage id="homeTitle" />
                  </Typography>
                  <Typography
                    variant="h4"
                    color={palette.grey[200]}
                    fontSize="40px"
                    fontWeight="400"
                  >
                    <FormattedMessage id="homeDescription" />
                  </Typography>
                </Box>
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  gap="20px"
                >
                  <CustomSelect />
                  <CustomButton
                    title={useIntl().formatMessage({ id: 'homeButton' })}
                    onClick={modalShow}
                    size="large"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Login show={show} setShow={setShow} />
      </Box>
    </Fragment>
  )
}

export default Home
