import React from "react";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";

import { validationErrors } from "../../constants/validationErrors";
import { validatePhoneNumber } from "../../utils";
import "react-phone-input-2/lib/bootstrap.css";

const Phone = ({ control, name, required, errors, label }) => {
  return (
    <Box className="phone-input">
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        sx={{
          fontFamily: "var(--font-p_m)",
          fontSize: { xs: "18", md: "20px" },
          fontWeight: 500,
          color: "#1e1e1ecc",
        }}>
        {label}
      </InputLabel>
      <FormControl>
        <Controller
          name={name}
          control={control}
          rules={{
            required: validationErrors.required,
            validate: (value) =>
              validatePhoneNumber(value) || validationErrors.phoneNumber,
          }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              enableLongNumbers={true}
              enableSearch={true}
              name={name}
              country="ae"
              value={value}
              specialLabel=""
              onChange={(e, selectedCountry) => {
                onChange(e + "/" + selectedCountry?.format);
              }}
              inputProps={{
                id: name,
                name,
                required,
              }}
            />
          )}
        />
        {errors?.[name]?.message && (
          <FormHelperText error>{errors?.[name]?.message}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default Phone;
