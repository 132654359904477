import React from "react";
import { Box } from "@mui/system";
import { Button, AppBar, Toolbar } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Header = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        flexGrow: 1,
        "& .MuiPaper-root": {
          boxShadow: "none",
          top: "40px",
          backgroundColor: "transparent",
        },
        "& .MuiToolbar-root": {
          justifyContent: "center",
          "& a": {
            fontSize: "20px",
            fontWeight: "500",
            padding: "0",
            fontFamily: "var(--font-p_m)",
            textTransform: "capitalize",
            color: palette.grey[200],
            "&:first-child": {
              marginRight: "20px",
            },
          },
        },
      }}
    >
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              "& button": { color: palette.grey[200] },
            }}
          >
            {/* <Button as={Link} to="/news">
              <FormattedMessage id="news" />
            </Button>
            <Button as={Link} to="/policies">
              <FormattedMessage id="policies" />
            </Button> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
