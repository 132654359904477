/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";
import { CustomButton, CustomInput, Wrapper } from "../../components";
import Select from "react-select";
import { validationErrors } from "../../constants/validationErrors";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userUpdate } from "../../slice/user.slice";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  countryOptions,
  countryOptionsAr,
  countryOptionsFr,
  affiliateOptions,
  affiliateOptionsAr,
  affiliateOptionsFr,
  legalStatusOptions,
  legalStatusOptionsAr,
  legalStatusOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const Registration = ({ onNext }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const { palette } = useTheme();
  const [state, setState] = useAppState();
  const userSelector = useSelector((state) => state.user);
  const userFormData = userSelector?.userFormData;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [countryLists, setCountryList] = useState(countryOptions);
  const [affiliateLists, setAffiliateLists] = useState(affiliateOptions);
  const [legalStatusLists, setLegalStatusLists] = useState(legalStatusOptions);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });

  const validationSchema = Yup.object().shape({
    EntityName: Yup.string().required(
      useIntl().formatMessage({ id: "enitityNameRequired" })
    ),
    DateOfIncorporation: Yup.date()
      .required(
        useIntl().formatMessage({ id: "dateofIncorporationisRequired" })
      )
      .max(new Date(), "Date must be in the past"),
    BusinessRegistrationExpiryDate: Yup.date()
      .required(
        useIntl().formatMessage({
          id: "businessRegistrationExpiryDateisRequired",
        })
      )
      .min(new Date(), "Date must be in the future"),
    LegalStatus: Yup.object().required(
      useIntl().formatMessage({ id: "legalStatusisRequired" })
    ),
    CountryofIncorporation: Yup.object().required(
      useIntl().formatMessage({ id: "countryofIncorporationisRequired" })
    ),
    BusinessRegistrationNumber: Yup.string().required(
      useIntl().formatMessage({ id: "businessRegistrationNumberisRequired" })
    ),
    AffiliateCode: Yup.object().required(
      useIntl().formatMessage({ id: "affiliateCodeisRequired" })
    ),
    NatureOfBusiness: Yup.string().required(
      useIntl().formatMessage({ id: "natureOfBusinessIsRequired" })
    ),
    // VendorWebsite: Yup.string().required(
    //   useIntl().formatMessage({ id: "vendorWebsiteIsRequired" })
    // ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userFormData?.AffiliateCode) {
      reset({
        AffiliateCode: affiliateLists.filter(
          (item) => item.value === userFormData?.AffiliateCode
        )[0],
        EntityName: userFormData?.EntityName,
        LegalStatus: legalStatusLists.filter(
          (item) => item.value === userFormData?.LegalStatus
        )[0],

        CountryofIncorporation: countryLists.filter(
          (item) => item.value === userFormData?.CountryofIncorporation
        )[0],
        DateOfIncorporation: new Date(userFormData?.DateOfIncorporation),
        BusinessRegistrationNumber: userFormData?.BusinessRegistrationNumber,
        BusinessRegistrationExpiryDate: new Date(
          userFormData?.BusinessRegistrationExpiryDate
        ),
        NatureOfBusiness: userFormData?.NatureOfBusiness,
        VendorWebsite: userFormData?.VendorWebsite,
        NumberOfEmployees: {
          value: userFormData?.NumberOfEmployees,
          label: userFormData?.NumberOfEmployees,
        },
      });
    }
    if (locale == "ar") {
      setCountryList(countryOptionsAr);
      setAffiliateLists(affiliateOptionsAr);
      setLegalStatusLists(legalStatusOptionsAr);
    } else if (locale == "fr") {
      setCountryList(countryOptionsFr);
      setAffiliateLists(affiliateOptionsFr);
      setLegalStatusLists(legalStatusOptionsFr);
    } else {
      setCountryList(countryOptions);
      setAffiliateLists(affiliateOptions);
      setLegalStatusLists(legalStatusOptions);
    }
  }, [userFormData]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    delete finalData?.confirmPassword;
    setState({ ...state, ...finalData });
    onNext();
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCountryList(countryOptionsAr);
      setAffiliateLists(affiliateOptionsAr);
      setLegalStatusLists(legalStatusOptionsAr);
    } else if (lang == "fr") {
      setCountryList(countryOptionsFr);
      setAffiliateLists(affiliateOptionsFr);
      setLegalStatusLists(legalStatusOptionsFr);
    } else {
      setCountryList(countryOptions);
      setAffiliateLists(affiliateOptions);
      setLegalStatusLists(legalStatusOptions);
    }
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="affiliate" />
                    </InputLabel>
                    <Controller
                      name="AffiliateCode"
                      control={control}
                      rules={{
                        required: validationErrors.required,
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={affiliateLists}
                        />
                      )}
                    />
                    {errors?.AffiliateCode?.message && (
                      <FormHelperText error>
                        {errors?.AffiliateCode?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "entityName" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterEntityName",
                    })}
                    name="EntityName"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.EntityName?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="legalStatus" />
                    </InputLabel>
                    <Controller
                      name="LegalStatus"
                      control={control}
                      rules={{
                        required: validationErrors.required,
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={legalStatusLists}
                        />
                      )}
                    />
                    {errors?.LegalStatus?.message && (
                      <FormHelperText error>
                        {errors?.LegalStatus?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid item sm={6} xs={12}>
                    <Box component="div" className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="countryOfIncorporation" />
                      </InputLabel>
                      <Controller
                        name="CountryofIncorporation"
                        control={control}
                        rules={{
                          required: validationErrors.required,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            options={countryLists}
                          />
                        )}
                      />
                      {errors?.CountryofIncorporation?.message && (
                        <FormHelperText error>
                          {errors?.CountryofIncorporation?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="dateOfIncorporation" />
                      </InputLabel>
                      <Controller
                        name="DateOfIncorporation"
                        control={control}
                        rules={{ required: validationErrors.required }}
                        render={({ field: { onChange, value = "" } }) => (
                          <DatePicker
                            className="date"
                            placeholderText={useIntl().formatMessage({
                              id: "selectDate",
                            })}
                            selected={value}
                            dateFormat={"dd/MM/yyyy"}
                            maxDate={new Date()}
                            popperPlacement="bottom"
                            onChange={(update) => {
                              onChange(update);
                            }}
                          />
                        )}
                      />
                      {errors?.DateOfIncorporation?.message && (
                        <FormHelperText error>
                          {errors?.DateOfIncorporation?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "businessRegistrationNumber",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterBusinessRegistrationNumber",
                      })}
                      name="BusinessRegistrationNumber"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.BusinessRegistrationNumber?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="registrationExpiryDate" />
                      </InputLabel>
                      <Controller
                        name="BusinessRegistrationExpiryDate"
                        control={control}
                        rules={{ required: validationErrors.required }}
                        render={({ field: { onChange, value = "" } }) => (
                          <DatePicker
                            className="date"
                            placeholderText={useIntl().formatMessage({
                              id: "selectDate",
                            })}
                            dateFormat={"dd/MM/yyyy"}
                            selected={value}
                            minDate={new Date()}
                            popperPlacement="bottom"
                            onChange={(update) => {
                              onChange(update);
                            }}
                          />
                        )}
                      />
                      {errors?.BusinessRegistrationExpiryDate?.message && (
                        <FormHelperText error>
                          {errors?.BusinessRegistrationExpiryDate?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "natureOfBusiness" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterNatureOfBusiness",
                    })}
                    name="NatureOfBusiness"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.NatureOfBusiness?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "vendorWebsite" })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterVendorWebsiteURL",
                    })}
                    name="VendorWebsite"
                    // rules={{
                    //   required: validationErrors.required,
                    // }}
                    // error={errors?.VendorWebsite?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box component="div" className="form-group">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <FormattedMessage id="numberOfEmployees" />{" "}
                    </InputLabel>
                    <Controller
                      name="NumberOfEmployees"
                      rules={{
                        required: validationErrors.required,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={customStyles}
                          options={[
                            { value: "< 10", label: "< 10" },
                            { value: "< 25", label: "< 25" },
                            { value: "< 50", label: "< 50" },
                            { value: "< 100", label: "< 100" },
                            { value: "< 500", label: "< 500" },
                            { value: "< 1000", label: "< 1000" },
                            { value: "< 5000", label: "< 5000" },
                            { value: "< 10000", label: "< 10000" },
                            { value: "> 10000", label: "> 10000" },
                          ]}
                        />
                      )}
                    />
                    {errors?.NumberOfEmployees?.message && (
                      <FormHelperText error>
                        {errors?.NumberOfEmployees?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  type="submit"
                  size="small"
                  variant="contained"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                />
                {/* {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleSubmit((data) => onSubmit(data, "update"))}
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Registration;
