/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton, CustomInput, Wrapper } from "../../components";
import { validationErrors } from "../../constants/validationErrors";
import { useTheme } from "@emotion/react";
import Select from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import { EMAIL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../utils";
import { userUpdate } from "../../slice/user.slice";
import Phone from "../../components/Phone";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";
import {
  countryOptions,
  countryOptionsAr,
  countryOptionsFr,
} from "../../utils";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "4",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "52px",
    backgroundColor: "hsl(0deg 0% 97.65%)",
    borderRadius: "10px",
  }),
};

const contactVOIPTypeOptions = [
  {
    value: "3CX WINDOWS VOIP PHONE",
    label: "3CX WINDOWS VOIP PHONE",
  },
  {
    value: "8x8",
    label: "8x8",
  },
  {
    value: "BOTIM",
    label: "BOTIM",
  },
  {
    value: "EKIGA",
    label: "EKIGA",
  },
  {
    value: "FACEBOOK",
    label: "FACEBOOK",
  },
  {
    value: "JITSI",
    label: "JITSI",
  },
  {
    value: "LINKEDIN",
    label: "LINKEDIN",
  },
  {
    value: "MICROSIP",
    label: "MICROSIP",
  },
  {
    value: "MICROSOFT TEAMS",
    label: "MICROSOFT TEAMS",
  },
  {
    value: "SOLARWINDS VOIP  NETWORK QUALITY MANAGER",
    label: "SOLARWINDS VOIP  NETWORK QUALITY MANAGER",
  },
  {
    value: "SKYPE",
    label: "SKYPE",
  },
  {
    value: "TEAMSPEAK",
    label: "TEAMSPEAK",
  },
  {
    value: "TWINKLE",
    label: "TWINKLE",
  },
  {
    value: "TWITTER",
    label: "TWITTER",
  },
  {
    value: "VIBER",
    label: "VIBER",
  },
  {
    value: "WECHAT",
    label: "WECHAT",
  },
  {
    value: "WHATSAPP",
    label: "WHATSAPP",
  },
  {
    value: "ZOIPER",
    label: "ZOIPER",
  },
  {
    value: "ZOOM",
    label: "ZOOM",
  },
];

const validationSchema = Yup.object().shape({
  ContactPersonFirstName: Yup.string().required("First Name is Required"),
  ContactPersonLastName: Yup.string().required("Last Name is Required"),
  ContactAddress: Yup.string().required("Contact Address Line 1 is Required"),
  ContactAddress2: Yup.string().required("Contact Address Line 2 is Required"),
  ContactCity: Yup.string().required("Contact City is Required"),
  ContactRegion: Yup.string().required(
    "Contact State/Province/Region is Required"
  ),
  ContactPostalCode: Yup.string().required("Contact Postal Code is Required"),
  ContactCountry: Yup.object().required("Contact Country is Required"),
  ContactEmail: Yup.string()
    .matches(EMAIL, "Invalid email address")
    .required("Contact Email is Required"),
  ContactPhone: Yup.string().required("Contact Phone is Required"),
});
const PersonInformation = ({ onNext, onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const { palette } = useTheme();
  const userFormData = useSelector((state) => state.user.userFormData);
  const [state, setState] = useAppState();
  const [countryLists, setCountryList] = useState(countryOptions);
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userFormData.ContactPersonFirstName && !state.ContactPersonFirstName) {
      reset({
        ContactPersonFirstName: userFormData.ContactPersonFirstName,
        ContactPersonLastName: userFormData.ContactPersonLastName,
        ContactAddress: userFormData.ContactAddress,
        ContactAddress2: userFormData.ContactAddress2,
        ContactCity: userFormData.ContactCity,
        ContactRegion: userFormData.ContactRegion,
        ContactPostalCode: userFormData.ContactPostalCode,
        ContactCountry: countryLists.filter(
          (option) => option.value === userFormData.ContactCountry
        )[0],
        ContactPhone: userFormData.ContactPhone,
        ContactEmail: userFormData.ContactEmail,
        ContactVOIPType: contactVOIPTypeOptions.filter(
          (option) => option.value === userFormData.ContactVOIPType
        )[0],
        ContactVoIPID: userFormData.ContactVoIPID,
      });
    }
    if (locale == "ar") {
      setCountryList(countryOptionsAr);
    } else if (locale == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  }, [userFormData]);

  const onSubmit = (data, name) => {
    setLoading(true);
    var finalData = data;
    finalData.ContactPhone = finalData.ContactPhone.split("/")[0];
    setState({ ...state, ...finalData });
    onNext();
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const handleSwitchLang = (lang) => {
    selectLanguage(lang);
    if (lang == "ar") {
      setCountryList(countryOptionsAr);
    } else if (lang == "fr") {
      setCountryList(countryOptionsFr);
    } else {
      setCountryList(countryOptions);
    }
  };

  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box>
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "firstName",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterFirstName",
                    })}
                    name="ContactPersonFirstName"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.ContactPersonFirstName?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "lastName",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterLastName",
                    })}
                    name="ContactPersonLastName"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.ContactPersonLastName?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "contactPersonAddressLine1",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterContactPersonAddressLine1",
                    })}
                    name="ContactAddress"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.ContactAddress?.message}
                    control={control}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "contactPersonAddressLine2",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterContactPersonAddressLine2",
                    })}
                    name="ContactAddress2"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.ContactAddress2?.message}
                    control={control}
                  />
                </Grid>
                <Grid container columnSpacing={3} sx={{ marginBottom: "20px" }}>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "contactCity",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterCity",
                      })}
                      name="ContactCity"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.ContactCity?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "contactStateProvinceRegion",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterContactStateProvinceRegion",
                      })}
                      name="ContactRegion"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.ContactRegion?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "contactPostalCode",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterContactPostalCode",
                      })}
                      name="ContactPostalCode"
                      rules={{
                        required: validationErrors.required,
                      }}
                      error={errors?.ContactPostalCode?.message}
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box component="div" className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="country" />
                      </InputLabel>
                      <Controller
                        name="ContactCountry"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            options={countryLists}
                          />
                        )}
                      />
                      {errors?.ContactCountry?.message && (
                        <FormHelperText error>
                          {errors?.ContactCountry?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <Phone
                    name="ContactPhone"
                    control={control}
                    label={useIntl().formatMessage({
                      id: "contactPersonPhone",
                    })}
                    required={true}
                    errors={errors}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({
                      id: "contactPersonEmail",
                    })}
                    type="text"
                    placeholder={useIntl().formatMessage({
                      id: "enterContactPersonEmail",
                    })}
                    name="ContactEmail"
                    rules={{
                      required: validationErrors.required,
                    }}
                    error={errors?.ContactEmail?.message}
                    control={control}
                  />
                </Grid>
                <Grid container columnSpacing={3} sx={{ marginBottom: "20px" }}>
                  <Grid item sm={6} xs={12}>
                    <Box component="div" className="form-group">
                      <InputLabel shrink htmlFor="bootstrap-input">
                        <FormattedMessage id="voip" />
                      </InputLabel>
                      <Controller
                        name="ContactVOIPType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            options={contactVOIPTypeOptions}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInput
                      label={useIntl().formatMessage({
                        id: "voipId",
                      })}
                      type="text"
                      placeholder={useIntl().formatMessage({
                        id: "enterVoipId",
                      })}
                      name="ContactVoIPID"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => handleSwitchLang("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  onClick={onPrev}
                />
                <CustomButton
                  title={useIntl().formatMessage({ id: "next" })}
                  size="small"
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data, "next"))}
                />
                {/* {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleSubmit((data) => onSubmit(data, "update"))}
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PersonInformation;
