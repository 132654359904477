import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  error: false,
  user: [],
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.user = payload;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setUser, setError } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;

export const getAccessToken = (body) => {
  var qs = require("qs");
  axios
    .post(
      "https://accounts.accesscontrol.windows.net/d9ca4589-67cc-41b3-8883-62a1ed7f4390/tokens/OAuth/2",
      qs.stringify({
        grant_type: "client_credentials",
        client_id:
          "0308973c-e821-4b47-8e97-1889f60b3b60@d9ca4589-67cc-41b3-8883-62a1ed7f4390",
        client_secret: "JGozRlPjVpPOa131IuODd91erVH6GpPX8fWCyCCe/30=",
        resource:
          "00000003-0000-0ff1-ce00-000000000000/oilibya.sharepoint.com@d9ca4589-67cc-41b3-8883-62a1ed7f4390",
      }),
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then(function (response) {
     
      console.log(response);
    });
};

export const accessTokenVal = async () => {
  const token = [];
  var qs = require("qs");
  try {
   
    const resp = await axios.post(
      "https://accounts.accesscontrol.windows.net/d9ca4589-67cc-41b3-8883-62a1ed7f4390/tokens/OAuth/2",
      qs.stringify({
        grant_type: "client_credentials",
        client_id:
          "0308973c-e821-4b47-8e97-1889f60b3b60@d9ca4589-67cc-41b3-8883-62a1ed7f4390",
        client_secret: "JGozRlPjVpPOa131IuODd91erVH6GpPX8fWCyCCe/30=",
        resource:
          "00000003-0000-0ff1-ce00-000000000000/oilibya.sharepoint.com@d9ca4589-67cc-41b3-8883-62a1ed7f4390",
      }),
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (resp.data.success) {
      token.push(resp.data.data[0].public_url);
    } else {
    }
  } catch (error) {
    console.log(error);
  }

  return token;
};
