import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@emotion/react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdditionalInformation from "./additional-information";
import BankDetails from "./bank-details";
import ContactInformation from "./contact-information";
import FinancialInformation from "./financial-information";
import OwnershipInformation from "./ownership-information";
import PaymentTerms from "./payment-terms";
import PersonInformation from "./person-information";
import References from "./references";
import SupportingDocuments from "./supporting-documents";
import Registration from "./registration";
import { ProgressBar } from "../../components";
import { useIntl } from "react-intl";
import { getProfile, logout } from "../../slice/user.slice";

const ITEM_HEIGHT = 48;

const steps = {
  0: Registration,
  1: FinancialInformation,
  2: BankDetails,
  3: PaymentTerms,
  4: ContactInformation,
  5: PersonInformation,
  6: OwnershipInformation,
  7: References,
  8: SupportingDocuments,
  9: AdditionalInformation,
};

const Root = styled("div")(({ theme }) => ({
  paddingInline: "20px",
  paddingBlock: "16px",
  "& .heading-block": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "15px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  "& .MuiTypography-h2": {
    fontFamily: "var(--font_et_r)",
    fontSize: "20px",
    color: theme.palette.secondary[700],
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  "& .MuiList-root": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
    "& .MuiListItem-root": {
      padding: "0",
      width: "fit-content",
      borderRadius: "100%",
    },
    "& .MuiButton-root": {
      width: "40px",
      height: "40px",
      minWidth: "40px",
      boxShadow: "none",
      borderRadius: "100%",
      "&:hover": {
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.grey[200],
      },
    },
  },
}));
const numberData = Array.from({ length: 10 }, (_, index) => index + 1);

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const userSelector = useSelector((state) => state.user);
  const userFormData = userSelector?.userFormData;
  const { palette } = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const Step = steps[step];
  let access_token = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
    if (id) {
      dispatch(getProfile(id));
    }
  }, [id]);

  function onNext() {
    setStep(step + 1);
  }
  function onPrev() {
    setStep(step - 1);
  }
  const stepTitles = [
    useIntl().formatMessage({ id: "vendorFinancialInformation(Entity)" }),
    useIntl().formatMessage({ id: "vendorFinancialInformation(Entity)" }),
    useIntl().formatMessage({ id: "vendorBankDetails(Entity)" }),
    useIntl().formatMessage({ id: "vendorPaymentTerms(Entity)" }),
    useIntl().formatMessage({ id: "vendorContactInformation" }),
    useIntl().formatMessage({ id: "contactPersonInformation" }),
    useIntl().formatMessage({ id: "vendorOwnershipInformation" }),
    useIntl().formatMessage({ id: "vendorReferences" }),
    useIntl().formatMessage({ id: "supportingDocuments" }),
    useIntl().formatMessage({ id: "additionalInformation" }),
  ];
  return (
    <Box component="section">
      <Root>
        <Box className="heading-block">
          <Typography variant="h2">{stepTitles[step]}</Typography>
          <List role="menubar" orientation="horizontal">
            {numberData?.map((item, index) => (
              <ListItem
                key={index}
                role="none"
                sx={{
                  outline: step === index ? "2px solid #FE5303" : "",
                  padding: step === index ? "2px !important" : "",
                }}
                onClick={() => id && userFormData && setStep(index)}
              >
                <Button
                  alt="More"
                  variant="contained"
                  sx={{
                    backgroundColor:
                      step >= index
                        ? palette.primary[500]
                        : "rgba(1, 113, 250, 0.12)",
                    color:
                      step >= index
                        ? palette.grey[200]
                        : palette.secondary[600],
                  }}
                >
                  {item}
                </Button>
              </ListItem>
            ))}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
              <MenuItem onClick={() => handleChangePassword()}>
                Change Password
              </MenuItem>
            </Menu>
          </List>
        </Box>
        <ProgressBar step={step} stepTitles={stepTitles.length} />
      </Root>
      <Box>
        <Step onNext={onNext} onPrev={onPrev} />
      </Box>
    </Box>
  );
};

export default RegistrationPage;
