import React, { Fragment, useEffect, useState } from "react";
import { Grid, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Header } from "../components";
import { loginWithMS } from "../slice/user.slice";
import MicrosoftIcon from "../assets/images/microsoft.svg";
import bannerImg from "../assets/images/banner.jpg";
import { showToast } from "../utils";

const HomeManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toast, setToast] = useState(false);
  const { instance } = useMsal();
  const [searchParams] = useSearchParams();
  const userSelector = JSON.parse(sessionStorage.getItem("ola_user_data"));
  const [isEmailVerified, setIsEmailVerified] = useState(
    searchParams.get("email") === "verified"
  );
  const { palette } = useTheme();
  useEffect(() => {
    if (userSelector) {
      userSelector?.user_type === "superadmin"
        ? navigate(`/dashboard/profile-management`)
        : navigate(`/dashboard/profile-management`);
    }
  }, []);

  const loginHandler = () => {
    instance
      .loginPopup({
        scopes: ["email"],
        prompt: "select_account",
      })
      .then((result) => {
        if (result.accessToken) {
          var body = {
            email: result.account.username,
            access_token: result.accessToken,
          };
          dispatch(loginWithMS(body))
            .then((res) => {
              console.log("xxxxxxxxx");
              setToast({ type: "success", message: res?.data?.data?.msg });
              res?.data?.data?.user?.user_type.toString() === "superadmin"
                ? navigate(`/dashboard/profile-management`)
                : navigate(`/dashboard/profile-management`);
            })
            .catch((err) => {
              setToast({
                type: "error",
                message: err?.data?.errors[0]?.msg,
              });
            });
        } else {
          setToast({
            type: "error",
            message: result.err,
          });
        }
      })
      .catch((err) => {
        setToast({
          type: "error",
          message: JSON.parse(JSON.stringify(err)).errorMessage,
        });
      });
  };

  return (
    <Fragment>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      {isEmailVerified && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          itemType="error"
          open={isEmailVerified}
          autoHideDuration={5000}
          message="Email has been verified"
          onClose={() => setIsEmailVerified(false)}
        ></Snackbar>
      )}
      <Header />
      <Box>
        <Box
          component="section"
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: "grid",
            placeItems: "center",
            background: `linear-gradient(125.73deg, ${palette.secondary[800]} 11.48%, ${palette.grey[300]} 104.84%)`,
            "& .MuiTypography-root": {
              marginBottom: "10px",
            },
            ".banner": {
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              zIndex: -1,
            },
          }}
        >
          <img src={bannerImg} alt="" className="banner" />
          <Grid container>
            <Grid item lg={5} mx="auto">
              <Box component="div" textAlign="center">
                <Box component="div" marginBottom="30px">
                  <Typography
                    variant="h1"
                    color={palette.grey[200]}
                    fontWeight="600"
                    fontFamily="var(--font_et_r)"
                  >
                    <FormattedMessage id="homeTitle" />
                  </Typography>
                  <Typography
                    variant="h4"
                    color={palette.grey[200]}
                    fontSize="40px"
                    fontWeight="400"
                  >
                    <FormattedMessage id="ManagerHomeDescription" />
                  </Typography>
                </Box>
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  gap="20px"
                >
                  <Box
                    sx={{ marginBlock: "20px 20px" }}
                    className="loginWithMicrosoft"
                  >
                    <div
                      onClick={() => loginHandler()}
                      style={{
                        borderRadius: "10px",
                        fontWeight: "500",
                        width: "100%",
                        textTransform: "capitalize",
                        fontFamily: "var(--font-p_sb)",
                        background: "#ffffff",
                        color: "#5e5e5e",
                        textAlign: "center",
                        padding: "12px",
                        border: "1px solid #8c8c8c",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={MicrosoftIcon}
                        alt="MicrosoftIcon"
                        style={{ width: "28px", marginRight: "12px" }}
                      />
                      <FormattedMessage id="loginWithMicrosoft" />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default HomeManager;
