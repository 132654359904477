import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import fileIcon from "../../assets/images/file.png";
import { FormattedMessage, useIntl } from "react-intl";
import { showToast } from "../../utils";

const InputDrag = ({
  title,
  name,
  errors,
  control,
  rules,
  values,
  disable,
}) => {
  const { palette } = useTheme();
  const [toast, setToast] = useState(false);
  const fileSizeError = useIntl().formatMessage({
    id: "fileSizeError",
  });

  const uploadFile = values?.[name]?.__metadata
    ? values?.[name]?.Url
    : values?.[name]
    ? URL.createObjectURL(values?.[name])
    : null;

  const Root = styled("div")(({ theme }) => ({
    "& .MuiFormControl-root": {
      position: "relative",
      "& .MuiOutlinedInput-root": {
        backgroundColor: palette.grey[400],
        height: "180px",
        backgroundImage: !uploadFile
          ? `linear-gradient(90deg, ${palette.secondary[100]} 50%, transparent 50%), 
                linear-gradient(90deg, ${palette.secondary[100]} 50%, transparent 50%), 
                linear-gradient(0deg, ${palette.secondary[100]} 50%, transparent 50%), 
                linear-gradient(0deg, ${palette.secondary[100]} 50%, transparent 50%)`
          : "",
        backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
        backgroundSize: "16px 2px, 16px 2px, 2px 16px, 2px 16px",
        backgroundPosition: "0% 0%, 100% 100%, 0% 100%, 100% 0px",
        borderRadius: "10px",
        cursor: "pointer",
        "& input": {
          opacity: "0",
          cursor: "pointer",
          padding: "0",
          zIndex: "1",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      "& .upload-box": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        textAlign: "center",
        "& img": {
          width: "104px",
          height: "104px",
          objectFit: "cover",
        },
        "& .MuiTypography-body2 ": {
          zIndex: "99",
          cursor: "pointer",
        },
        "& label": {
          fontFamily: "var(--font-p_m)",
          fontWeight: 500,
          fontSize: "14px",
          textTransform: "capitalize",
          cursor: "pointer",
          color: palette.secondary[700],
          [theme.breakpoints.up("sm")]: {
            fontSize: "16px",
          },
        },
      },
      "&.has-file": {
        "& input": {
          display: "none",
        },
      },
    },
  }));

  const isPdf = () => {
    if (uploadFile?.split("/")?.pop()?.includes(".pdf")) {
      return true;
    }
    if (values?.[name]?.name && values?.[name]?.name?.includes(".pdf")) {
      return true;
    }
    return false;
  };
  return (
    <>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Root className="form-group">
        <InputLabel shrink htmlFor="bootstrap-input">
          {title}
        </InputLabel>
        <FormControl className={uploadFile ? "has-file" : ""}>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                type="file"
                name={name}
                accept="image/*"
                id={`fileUpload-${name}`}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const fileSizeInMB = file.size / (1024 * 1024);
                  if (fileSizeInMB > 5) {
                    return setToast({
                      message: fileSizeError,
                      type: "error",
                    });
                  } else {
                    onChange(e.target.files[0]);
                  }
                }}
                hidden
                multiple
                focused={false}
              />
            )}
          />
          <Box component="div" className="upload-box">
            {uploadFile && (
              <a href={uploadFile} target="_blank" rel="noreferrer">
                <img
                  src={isPdf() ? fileIcon : uploadFile}
                  alt="preview"
                  width="200"
                  height="200"
                />
              </a>
            )}
            <Typography
              variant="body1"
              sx={
                disable
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              {uploadFile ? (
                values?.[name]?.name || uploadFile.split("/").pop()
              ) : (
                <FormattedMessage id="dragFilesOrClickToBrowse" />
              )}
            </Typography>
            <Box
              sx={
                disable
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              {uploadFile && (
                <label htmlFor={`fileUpload-${name}`}>Click to change</label>
              )}
            </Box>
          </Box>
        </FormControl>

        {errors?.[name]?.message && (
          <Typography variant="body1" color="error">
            {errors?.[name]?.message}
          </Typography>
        )}
      </Root>
    </>
  );
};

export default InputDrag;
