import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { IconButton, InputLabel, TextField, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { Controller } from "react-hook-form";
export default function InputPassword({
  label,
  placeholder,
  name,
  error,
  control,
  rules,
}) {
  const [show, setShow] = useState(false);
  return (
    <Box component="div" className="form-group">
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <FormControl>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <TextField
              type={show ? "text" : "password"}
              name={name}
              {...field}
              placeholder={placeholder}
              focused={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
            />
          )}
        />
        <Typography variant="body1" className="error">
          {error}{" "}
        </Typography>
      </FormControl>
    </Box>
  );
}
