import React from "react";
import { Heading, Wrapper } from "../components";
const TermsConditions = () => {
  return (
    <Wrapper>
      <Heading title="Terms Conditions" />
    </Wrapper>
  );
};

export default TermsConditions;
