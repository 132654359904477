import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { lazy, memo, Suspense, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
const Sidebar = lazy(() => import("../components/sidebar"));
const BreadCrumbs = lazy(() => import("../components/BreadCrumb"));

export default function MainLayout() {
  const { palette } = useTheme();
  const { id } = useParams();
  const { pathname } = window.location;
  const valueAfterLastSlash = pathname.substring(pathname.lastIndexOf("/") + 1);
  const isReg = valueAfterLastSlash !== "registration";

  const userSelector = JSON.parse(sessionStorage.getItem("ola_user_data"));
  const [show, setShow] = useState(false);
  const isNotVendor = ["superadmin", "manager"].includes(
    userSelector?.user_type.toString()
  );
  const menuOpen = () => {
    setShow(!show);
  };
  const Wrapper = styled("section")(({ theme }) => ({
    background: palette.grey[100],
    minHeight: "100vh",
    "& .right-side": {
      padding: "0",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "265px",
      },
    },
  }));

  return (
    <Wrapper>
      <BreadCrumbs menuOpen={menuOpen} show={show} />
      {isNotVendor ? (
        <Box position="absolute" width="100%">
          <Suspense fallback={<p>loading...</p>}>
            {!id && isReg && <Sidebar show={show} />}
          </Suspense>
          <Box className="right-side">
            <Outlet />
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </Wrapper>
  );
}
